<div [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-auto-name" class="modal-title pull-left">
          <span class="mobi_first_line">Welcome to ABMP&rsquo;s </span>
          <span class="mobi_second_line">Pocket Pathology</span>
           </h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <p>
          ABMP has partnered with Ruth Werner, author of <em>A Massage Therapist&rsquo;s Guide to Pathology</em>,
          to create this exclusive pathology app for ABMP members. Watch this short demo, or get started now.
        </p>
        <app-video
        class="card-img-top"
        Poster="https://cdn.abmp.com/public/image/pocket-pathology/RuthWelcome_v2.jpg"
        [AutoPlay]="false"
        [source]="[{ src: this.vidSourceIntro,
        type: 'application/x-mpegURL' }]"></app-video>

      </div>
    </div>
  </div>
</div>
