import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faBars, faChevronLeft, faSearch, faTimes, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkSolid, faHistory, faUndo, faHome } from '@fortawesome/free-solid-svg-icons';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pathology } from 'src/app/models/pathology.model';

import { PathologyService, PathologyTitleMatch } from '../../../services/pathology.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  public hideSearch: boolean = false;

  @Input()
  public hideMenu: boolean = true;

  @ViewChild('search', { read: ElementRef })
  public search: ElementRef<HTMLInputElement>;

  @ViewChild('nav', { static: true })
  public nav: ElementRef<HTMLElement>;

  public search$: Subject<string>;

  public Pathology$: Observable<Pathology[]>;

  public searchOpen: boolean = false;
  public searchIsSet: boolean = false;

  public faHome = faHome;
  public faHistory = faHistory;
  public faUndo = faUndo;
  public faBookmarkSolid = faBookmarkSolid;
  public faSearch = faSearch;
  public faChevronLeft = faChevronLeft;
  public faTimes = faTimes;
  public faWindowClose = faWindowClose;
  public faBars = faBars;

  constructor(
    private pathology: PathologyService,
    private router: Router,
    private location: Location) {
    this.search$ = new Subject();
  }

  public ngOnInit(): void {
    this.searchIsSet = false;

    this.search$.subscribe(term => console.log(term));
    this.Pathology$ = combineLatest([this.search$, this.pathology.PathoNodes$]).pipe(
      map(([term, pathologies]) => {
        console.log(pathologies, term);
        return pathologies.filter(pathology => PathologyTitleMatch(term, pathology));
      })
    );
  }

  public ngAfterViewInit(): void {
  }

  public ngOnChanges(): void {
    if (this.hideSearch && this.searchOpen) {
      this.searchOpen = false;
    }
  }

  public onTransitionEnd(event: TransitionEvent): void {
    if (event.propertyName === 'width') {
      if (this.nav.nativeElement.classList.contains('searchOpen')) {
        this.searchIsSet = true;
        this.search.nativeElement.focus();
      } else {
        this.search.nativeElement.blur();
        this.searchIsSet = false;
      }
    }
  }

  public onSelect(event: TypeaheadMatch): void {
    this.search.nativeElement.blur();
    this.search$.next(null);
    this.router.navigate(['/pathology', event.item.alias]);
  }

  public back(): void {
    this.location.back();
  }

  public close(): void {
    timer(250).subscribe(() => {
      this.searchOpen = false;
    });
  }

}
