import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { Pathology } from '../models/pathology.model';
import { ConnectionService } from './connection.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PathologyService implements OnDestroy {

  private apiEndpoint: string = 'https://umbraco-api.azurewebsites.net/api/pocketpatho/nodes';
  private pathoNodes$: BehaviorSubject<Pathology[]> = new BehaviorSubject(null);

  private sink: SubSink = new SubSink();
  private token: string = null;
  public get PathoNodes$(): Observable<Pathology[]> {
    return this.pathoNodes$;
  }

  constructor(
    private httpClient: HttpClient,
    private user: UserService,
    private connection: ConnectionService
  ) {
    user.UserState$.subscribe(u => this.token = u.token);
    this.fetchNodes();
  }

  public ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  private fetchNodes(): void {
    this.sink.sink = this.user.UserState$.pipe(
      filter(user => user !== null && user.IsLoggedIn() && (!this.connection.State || user.fromCache === false)),
      switchMap(() => this.httpClient
        .get<Pathology[]>(this.apiEndpoint, { headers: { "Authorization": "Bearer " + this.token } })
        .pipe(

          // Convert to an array of Pathology
          map(nodes => {
            if (nodes instanceof Array) {
              return nodes.map(node => new Pathology(node));
            } else {
              return [];
            }
          }),

          // Resolve related Pathologies
          map(nodes => {
            for (const node of nodes) {
              // Make sure the property on the node is already an array so we can push to it
              if (!(node.related_pathologies instanceof Array)) {
                node.related_pathologies = [];
              }

              if (node.related_pathology_titles instanceof Array) {
                // This array is already trimmed and lower-cased in the PathoNode constructor
                for (const title of node.related_pathology_titles) {
                  const related = nodes.find(n => n.title.trim().toLowerCase() === title || n.alias.trim().toLowerCase() === title);

                  if (related) {
                    node.related_pathologies.push({
                      nid: related.nid,
                      title: related.title,
                      alias: related.alias
                    });
                  }
                }
              }
            }

            return [...nodes];
          }),

          // Sort
          map(nodes => {
            nodes.sort((a, b) => {
              const t1 = a.title.trim().toLowerCase();
              const t2 = b.title.trim().toLowerCase();
              return t1.localeCompare(t2);
            });

            return [...nodes];
          })
        )
      )).subscribe(nodes => {
        this.pathoNodes$.next(nodes);
      });
  }

  public GetSingle(alias: string): Observable<Pathology> {
    return this.pathoNodes$.pipe(
      map(nodes => nodes === null ? null : nodes.find(node => node.alias.toLowerCase() === alias.trim().toLowerCase()))
    );
  }

}

export function PathologyTitleMatch(term: string, pathology: Pathology): boolean {
  let match: boolean;
  // Title
  match = match || pathology.title.toLowerCase().indexOf(term) !== -1;

  if (pathology.common_names_search_terms) {
    // Related common names/search terms
    match = match || (pathology.common_names_search_terms.some(item => item.indexOf(term) !== -1));
  }

  return match;
}
