import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Constantvars } from '../../../constantvars';
import { Location } from '@angular/common';
import { PreviousRouteService } from '../../../services/previous-route.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public vidURL: string;
  public vidJpg: string;
  public vidType: string;
  public autoplay: boolean;
  public AppConstants: any;
  public faChevronLeft = faChevronLeft;
  public pgTitle: string;
  public prevTitle: string;
  public prevURL: string;

  constructor(
    private location: Location,
    private previousRouteService: PreviousRouteService
  ) {
    this.AppConstants = Constantvars;
    this.vidType = Constantvars.vidType;
    this.autoplay = Constantvars.autoplay;
    this.vidURL = Constantvars.vidBucket + 'pocketpatho-Long-Intro/Default/HLS/pocketpatho-Long-Intro.m3u8';
    this.vidJpg = Constantvars.popaImgs + 'RuthWelcome_v2.jpg';
    this.pgTitle = 'Welcome to Pocket Pathology';
    this.prevTitle = 'Videos, Forms, and Resources';
}
  ngOnInit(): void {
    this.prevURL = this.previousRouteService.getPreviousUrl()
    if (this.prevURL === '/faqs') {
      this.prevTitle = 'Frequently Asked Questions';
    } else {
      this.prevTitle = 'Videos, Forms, and Resources';
    }
  }
  public back(): void {
    this.location.back();
  }
}
