import 'hammerjs';

import { APP_BASE_HREF } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppSettings, AvailableDrupalVersions, BootstrapVersion, DrupalVersion, INDIVIDUAL } from 'pac-api';

import { AppModule } from './app/app.module';
import { JwtToken } from './app/models/jwt.model';

try {
  let jwt = null;
  const headers = new Headers();

  const appSettings = fetch('assets/appSettings.json', {
    credentials: 'omit',
  });

  //**********************
  const user = fetch('/api/individual', {
    credentials: 'include',
    headers,
  });
  //*********************** */

  Promise.all([appSettings, user])
    .then(([appSettings, user]) => {
      return Promise.all([appSettings.json(), user.json()]);
    })
    .then(([appSettings, user]) => {
      const drupal: AvailableDrupalVersions = appSettings.drupal;

      //if (!jwt && user && user.JWT) {
      //  jwt = user.JWT;
      //  localStorage.setItem('JWT_TOKEN', user.JWT);
      //}

      return platformBrowserDynamic([
        {
          provide: JwtToken,
          useValue: new JwtToken(jwt),
        },
        {
          provide: AppSettings,
          useValue: appSettings,
        },
        {
          provide: INDIVIDUAL,
          useValue: user,
        },
        {
          provide: APP_BASE_HREF,
          useValue: '/',
        },
        {
          provide: DrupalVersion,
          useValue: drupal,
        },
        {
          provide: BootstrapVersion,
          useValue: 'bs4',
        }
      ])
        .bootstrapModule(AppModule);
    })
    .catch(err => console.error(err));
} catch (err) {
  console.error(err);
}

