import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Output()
  public pathologySearch: EventEmitter<[string, boolean]> = new EventEmitter(true);

  public faSearch = faSearch;
  public faTimes = faTimes;

  constructor() {
  }

  public ngOnInit(): void {
    this.pathologySearch.emit([null, false]);
  }

  public Search(term: string, deep: boolean): void {
    term = term.trim().toLowerCase();
    this.pathologySearch.emit([term, deep]);
  }

}
