import { Component, OnInit } from '@angular/core';
import { Constantvars } from '../../constantvars';

@Component({
  selector: 'app-about-popa',
  templateUrl: './about-popa.component.html',
  styleUrls: ['./about-popa.component.scss']
})
export class AboutPopaComponent implements OnInit {

  public AppConstants: any;
  public pgTitle: string;
  public popaImgs: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.popaImgs = Constantvars.popaImgs;
    this.pgTitle = 'About Pocket Pathology';
   }

  ngOnInit() {
  }

}
