import { Component, OnInit, Input } from '@angular/core';
import { Pathology } from '../../models/pathology.model';

@Component({
  selector: 'app-pathology-short',
  templateUrl: './pathology-short.component.html',
  styleUrls: ['./pathology-short.component.scss']
})
export class PathologyShortComponent implements OnInit {

  @Input()
  public Pathology: Pathology;

  constructor() { }

  public ngOnInit(): void {
  }

}
