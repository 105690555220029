<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <hr>

        <img src="https://cdn.abmp.com/public/image/pocket-pathology/ruth-werner-portrait2-308px.jpg" class="border border-primary portrait img-fluid float-left mr-3 mb-1 mt-2" />

        <p class="pull-right">As part of ABMP&rsquo;s commitment to give our members the very best in resources and business tools,
          we are proud to partner with Ruth Werner to bring you Pocket Pathology.
          Ruth is the author of <em>A Massage Therapist&rsquo;s Guide to Pathology</em>, considered the industry&rsquo;s gold standard on pathology protocols.
          Here&rsquo;s more about this industry leader:</p>


        <p>Ruth volunteers with several industry organizations, including
          the Massage Therapy Foundation, where she served as president for four years. She was a practicing massage therapist
          for 15 years, and she teaches in massage therapy programs around the world. She is an NCBTMB-approved continuing
          education provider and a longtime columnist with <em>Massage &amp; Bodywork</em>.</p>

        <p>Ruth has served in numerous roles for the National Certification Board for Therapeutic Massage and Bodywork,
          the Federation of State Massage Therapy Boards, the Alliance for Massage Therapy Education, and the Fascia
          Research Society. Ruth was honored to receive the John Balletto Distinguished Service Award
          from the Massage Therapy Foundation in 2018.</p>

      </main>
    </div>
  </div>
</div>
