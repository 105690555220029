<ng-container dropdown [placement]="Placement + ' right'" [dropup]="Placement === 'top'">

  <button [ngClass]="ButtonClass" class="btn hideMobi mr-lg-0 mr-xl-3 align-top px-md-1" type="button" routerLink="/favorite">
    <fa-icon class="h4 pr-md-1" [icon]="faBookmarkSolid"></fa-icon>
    <span class="btn_text_header  pl-lg-1">Saved</span>
  </button>
  <button [ngClass]="ButtonClass" class="btn hideMobi mr-lg-0 mr-xl-3 align-top px-md-1 " type="button" routerLink="/recent">
    <fa-icon class="h4 pr-md-1" [icon]="faUndo"></fa-icon>
    <span class="btn_text_header  pl-lg-1">Recent</span>
  </button>

  <button dropdownToggle class="btn" [ngClass]="ButtonClass" type="button" aria-controls="dropdown-basic">
    <fa-icon class="h4" [icon]="faBars"></fa-icon>
    <span *ngIf="ButtonText" class="btn_text" [innerText]="ButtonText"></span>
  </button>

  <nav *dropdownMenu class="dropdown-menu dropdown-menu-right py-0" role="menu" aria-label="Navigation Menu">
    <div class="list-group">
      <!-- <a class="dropdown-item list-group-item hideMobi" [routerLink]="['/favorite']">Saved Pathologies</a>
      <a class="dropdown-item list-group-item hideMobi" [routerLink]="['/recent']">Recent Pathologies</a> -->
      <a class="dropdown-item list-group-item hideMobi" [routerLink]="['/']">Home</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/videos-and-resources']">Videos, Forms, and Resources</a>
      <a class="dropdown-item list-group-item hideMobi" [routerLink]="['/faqs']">FAQ</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/about-this-app']">About Pocket Pathology</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/about-ruth']">About Ruth Werner</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/buy-the-book']">Buy the Book </a>
      <!-- <a class="dropdown-item list-group-item hideMobi" [routerLink]="['/help']">Help </a> -->

      <a class="dropdown-item list-group-item" href="https://www.abmp.com/members" target="_blank">ABMP.com</a>
      <a class="dropdown-item list-group-item" [routerLink]="['/add-to-home-screen']">Add App to Home Screen</a>

    </div>
  </nav>
</ng-container>
