import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { tokenGetter } from '../../../services/tokenGetter';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public username: FormControl;
  public password: FormControl;

  public Busy$: Subject<boolean> = new Subject();
  public Error$: Subject<Error> = new Subject();

  private sink: SubSink = new SubSink();

  constructor(
    private userService: UserService,
    private router: Router) {
  }

  public ngOnInit(): void {
    this.Busy$.next(false);

    this.username = new FormControl(null, [ Validators.required, Validators.email ]);
    this.password = new FormControl(null, [ Validators.required ]);

    this.form = new FormGroup({
      username: this.username,
      password: this.password,
    });
  }

  public ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  public Submit(): void {
    if (this.form.valid) {
      this.Busy$.next(true);

      this.sink.sink = this.userService.Login(this.username.value, this.password.value).subscribe({
        next: user => {
          this.sink.unsubscribe();
          // Redirect handled by parent component
        },
        error: error => {
          this.sink.unsubscribe();
          this.Error$.next(error);
          this.Busy$.next(false);
        }
      });
    }
  }

}
