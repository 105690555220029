import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { SubSink } from 'subsink';
import { User } from '../../models/user.model';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private subsink: SubSink = new SubSink();

  constructor(
    private userService: UserService,
    private router: Router) {
    }

  public ngOnInit(): void {
    this.subsink.sink = this.userService.UserState$.subscribe(user => {
      if (user instanceof User) {
        if (user.fromCache === false) {
          this.router.navigate(['/']);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subsink) {
      this.subsink.unsubscribe();
    }
  }

}
