<form class="card" [formGroup]="form">
  <h1 class="card-title text-center cleanBorder pt-4">ABMP Account Login</h1>

  <fieldset class="card-body" [disabled]="Busy$ | async">

    <alert type="danger" *ngIf="Error$ | async as Error">
      <p [innerText]="Error.message"></p>
    </alert>

    <div class="form-group">
      <label>Email</label>
      <input [formControl]="username" class="form-control" [ngClass]="{ 'is-invalid': username.invalid && username.touched }" type="email" required />
      <p *ngIf="username.untouched || username.valid; else usernameError" class="form-text text-muted">Email address or ABMP username</p>
      <ng-template #usernameError>
        <p class="invalid-feedback">
          Email is required and must be a valid email address.
        </p>
      </ng-template>
    </div>

    <div class="form-group">
      <label>Password</label>
      <input [formControl]="password" class="form-control" [ngClass]="{ 'is-invalid': password.invalid && password.touched }" type="password" required />
      <p *ngIf="password.untouched || password.valid; else passwordError" class="form-text text-muted">Your ABMP account password</p>
      <ng-template #passwordError>
        <p class="invalid-feedback">
          Password is required
        </p>
      </ng-template>
    </div>

    <div class="row justify-content-md-center">
      <button [disabled]="form.invalid" type="submit" class="btn btn-lg btn-primary col-md-6" (click)="Submit()">Login</button>
    </div>

  </fieldset>
</form>
