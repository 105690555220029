<fieldset>

  <div class="form-row mb-1">
    <div class="col-12 text-right">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="search-deep" #searchRelatedTerms (change)="Search(searchTerm.value, searchRelatedTerms.checked)">
        <label class="form-check-label text-light" for="search-deep">Search all content</label>
      </div>
    </div>
  </div>

  <div class="form-row justify-content-center mb-3">
    <div class="col-12">
      <label class="sr-only" for="search-term" id="search-term-label">Search for pathology</label>

      <div class="input-group">
        <div class="input-group-prepend">
          <em class="input-group-text">
            <fa-icon [icon]="faSearch">Search</fa-icon>
          </em>
        </div>

        <input class="form-control" #searchTerm
        (input)="Search(searchTerm.value, searchRelatedTerms.checked)" id="search-term" type="search" autocomplete="off"
        aria-describedby="search-term-label" autofocus required>
        <div class="input-group-append"
        *ngIf="(searchTerm.value.trim() !== '')" >
          <button class="btn btn-outline-secondary" type="reset"
          (click)="Search(searchTerm.value = '', searchRelatedTerms.checked = false);"><fa-icon [icon]="faTimes">Clear</fa-icon></button>
        </div>
      </div>

    </div>
  </div>

</fieldset>
