<nav #nav class="navbar navbar-light" [ngClass]="{ searchOpen: searchOpen, searchClose: !searchOpen }">
  <div class="col mobi_bck d-lg-none d-md-none pl-0 pr-0" ngClass="'col-sm-2 col-2 '">
    <button *ngIf="!hideSearch || !hideMenu" class="btn btn-outline-secondary back outline-none left" type="button" (click)="back()">
      <fa-icon [icon]="faChevronLeft">ChevronLeft</fa-icon>
    </button>
  </div>

  <div class="col brand_wrap px-0" ngClass="col-md-6 col-lg-6 col-8">
    <a class="navbar-brand" [routerLink]="'/'">
      <img class="image logo" src="assets/images/pocket-pathology-logo-white.svg">
    </a>
  </div>

  <div class="col search_wrap" [ngClass]="{'col-sm-12 col-12 SearchIsOpen': searchOpen, 'col-sm-2 col-md-3 col-lg-3 col-2 pr-0': !searchOpen}">
    <ng-container *ngIf="!hideMenu">
      <form *ngIf="!hideSearch" (transitionend)="onTransitionEnd($event)" class="my-0 mr-0 my-lg-0  justify-content-end">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary d-md-none outline-none right" type="button" (click)="searchOpen = !searchOpen">
              <fa-icon [icon]="faSearch">Search</fa-icon>
            </button>
            <em class="input-group-text d-none d-md-block">
              <fa-icon [icon]="faSearch">Search</fa-icon>
            </em>
          </div>
          <input name="search" #search [ngModel]="search$ | async" (ngModelChange)="search$.next($event.trim().toLowerCase())" [typeahead]="Pathology$" typeaheadOptionField="title" [isAnimated]="true" [adaptivePosition]="true" [typeaheadItemTemplate]="searchItem" (typeaheadOnSelect)="onSelect($event)" class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" (blur)="close()">
        </div>
      </form>
    </ng-container>
  </div>

  <div class="col-lg-3 col-1 d-none d-md-block text-right pr-0 col-md-3">
    <app-menu-dropdown *ngIf="!hideMenu" class="nav-item " [ButtonClass]="['btn-outline-secondary', 'outline-none', 'btn-trans-white']"></app-menu-dropdown>
  </div>
</nav>

<ng-template #searchItem let-item="item">
  <h6 [routerLink]="['/pathology', item.alias]">{{ item.title }}</h6>
</ng-template>
