import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { slideInAnimation } from './route-animation';
import { AuthGuardService } from './services/auth-guard.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  public hideSearch: boolean = false;

  public hideMenu: boolean = true;

  public guardLoader$: Observable<boolean>;

  private sink: SubSink = new SubSink();

  public version: string = '1.0.0';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private user: UserService,
    private dd: DeviceDetectorService,
    private guard: AuthGuardService,
    private toast: ToastrService,
    ) {//private updates: SwUpdate) {
  }

  public ngOnInit(): void {
    this.guardLoader$ = this.guard.Loader$;

    const device = this.dd.getDeviceInfo();

    document.body.classList.add(device.browser, device.os);

    if (this.dd.isMobile()) {
      document.body.classList.add('Mobile');
    }

    if (this.dd.isDesktop()) {
      document.body.classList.add('Desktop');
    }

    if (this.dd.isTablet()) {
      document.body.classList.add('Tablet');
    }

    this.sink.sink = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideSearch = (event.urlAfterRedirects === '/');

        const route = reduceRoute(this.route.snapshot);

        if ('title' in route.data) {
          if (route.data.title) {
            this.title.setTitle(route.data.title);
          }
        } else {
          this.title.setTitle('ABMP Pocket Pathology');
        }
      }
    });

    this.sink.sink = this.user.UserState$.pipe(
      filter(user => user !== null && user.IsLoggedIn()),
      take(1)
    ).subscribe(() => {
      this.hideMenu = false;
    });

    /*this.sink.sink = this.updates.available.subscribe(e => {
      this.toast.info('A new app update is available. Click here to install.', 'Update', {
        disableTimeOut: true,
        positionClass: 'toast-bottom-center',
        closeButton: true
      }).onTap.pipe(take(1)).subscribe(() => document.location.reload());
    });*/
  }

  public ngAfterViewInit(): void {

  }

  public ngOnDestroy() {
    this.sink.unsubscribe();
  }
}

const reduceRoute = (route: ActivatedRouteSnapshot) => route.firstChild ? reduceRoute(route.firstChild) : route;
