import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  public vidSourceIntro = 'https://videos.abmp.com/public/pocketpatho-Long-Intro/Default/HLS/pocketpatho-Long-Intro.m3u8';

  @ViewChild('autoShownModal')
  public autoShownModal: ModalDirective;

  @Output()
  public Hidden: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public CloseModalForever(): void {
    if (localStorage && localStorage instanceof Storage) {
      localStorage.setItem('ModalHasBeenShown', JSON.stringify(true));
    }
  }

  public hideModal(): void {
    this.autoShownModal.hide();
  }

  public onHidden(): void {
    this.Hidden.emit();
    this.CloseModalForever();
  }

}
