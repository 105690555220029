<div class="container-lg">
  <div class="row" *ngIf="Pathology$ | async as Pathology">
    <main class="col-lg-4 pr-lg-3 col-md-6 ">
      <h1 class="mt-1">
        <app-favorite-button [isFavorite]="(isFavPatho$ | async)" class="float-right" (toggle)="ToggleFaveStar($event)"></app-favorite-button>{{ Pathology.title }}
      </h1>
      <div [innerHTML]="Pathology.body">

      </div>
    </main>
    <div class="mt-2 col-lg-8 pl-lg-3 col-md-6 ">
      <tabset type="tabs">
        <section tab class="pl-1" heading="Overview" [innerHTML]="Pathology.overview"></section>
        <section tab class="pl-1" heading="Adaptations" [innerHTML]="Pathology.adaptations"></section>
        <section tab class="pl-1" heading="Outcomes" [innerHTML]="Pathology.outcomes"></section>
      </tabset>

      <accordion #accordion [isAnimated]="true" [closeOthers]="true">
        <accordion-group #related *ngIf="Pathology.related_pathologies && Pathology.related_pathologies.length">
          <button class="btn btn-link btn-block clearfix text-left" accordion-heading type="button">
            <fa-icon [icon]="related.isOpen ? faChevronUp : faChevronDown"></fa-icon> Related Pathologies
          </button>
          <ul>
            <li *ngFor="let item of Pathology.related_pathologies"><a [routerLink]="['/pathology', item.alias]">{{ item.title }}</a></li>
          </ul>
        </accordion-group>
        <accordion-group #notes>
          <button class="btn btn-link btn-block clearfix text-left" accordion-heading type="button">
            <fa-icon [icon]="notes.isOpen ? faChevronUp : faChevronDown"></fa-icon> Notes
          </button>
          <div appAnchor [innerHTML]="Pathology.notes"></div>
        </accordion-group>
        <accordion-group #medications>
          <button class="btn btn-link btn-block clearfix text-left" accordion-heading type="button">
            <fa-icon [icon]="medications.isOpen ? faChevronUp : faChevronDown"></fa-icon> Medications
          </button>
          <div appAnchor [innerHTML]="Pathology.medications"></div>
        </accordion-group>
        <accordion-group *ngIf="Pathology.research_and_resources && Pathology.research_and_resources.trim() !== ''" #rnr>
          <button class="btn btn-link btn-block clearfix text-left" accordion-heading type="button">
            <fa-icon [icon]="rnr.isOpen ? faChevronUp : faChevronDown"></fa-icon> Research and Resources
          </button>
          <div appAnchor [innerHTML]="Pathology.research_and_resources"></div>
        </accordion-group>
      </accordion>
    </div>
  </div>
