import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Pathology } from 'src/app/models/pathology.model';
import { PathologyService } from 'src/app/services/pathology.service';

@Component({
  templateUrl: './pathology-list.component.html',
  styleUrls: ['./pathology-list.component.scss']
})
export class PathologyListComponent implements OnInit {

  public Pathologies$: Observable<Pathology[]>;

  private filter$: Subject<string[]> = new BehaviorSubject(null);

  constructor(private pathologyService: PathologyService) { }

  public ngOnInit(): void {
    this.Pathologies$ = combineLatest([this.filter$, this.pathologyService.PathoNodes$]).pipe(
      map(([filter, nodes]) => {

        if (!filter || filter.length === 0) {
          return nodes;
        }

        return nodes.filter(node => filter.some(letter => node.title[0] === letter));
      }),
      tap(nodes => console.log(nodes.length))
    );
  }

  public filter(...param: string[]): void {
    this.filter$.next(param);
  }

}
