import { AfterViewChecked, Directive, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appAnchor]'
})
export class AnchorDirective implements AfterViewChecked {

  constructor(private element: ElementRef<HTMLElement>) {
  }

  public ngAfterViewChecked(): void {
    this.element.nativeElement.querySelectorAll('a[href]').forEach((anchor: HTMLAnchorElement) => {
      anchor.target = '_blank';
      anchor.rel = 'noopener';
    });
  }

}
