<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <p class="mb-3 "><strong class="RuthSubTitle">By Ruth Werner, author of <em>A Massage Therapist’s Guide to Pathology</em></strong></p>

        <button [routerLink]="['/videos-and-resources']"
        [ngClass]="AppConstants.btnClassBackToReso "
        type="button">
          <fa-icon [icon]="faChevronLeft" class="mr-2"></fa-icon>Videos, Forms, and Resources
        </button>

        <!-- <h1 class="mt-1 mb-2 ">Hygienic Guidelines for Your Practice </h1>
        <p class="mb-3 "><span>By Ruth Werner, author of <em>A Massage Therapist’s Guide to Pathology</em></span></p> -->

        <p class="mt-3">No matter whether you work at
          home, in a spa, or in a hospital, strict hygienic procedures are a central component of a safe
          and professional practice. The hygienic practice guidelines provided here are drawn from the Centers for Disease Control
          and Prevention (CDC), the World Health Organization, and other resources for health-care
          professionals in hospital, dental, and home-care settings. Individual states may also have
          specific guidelines for massage therapists. Some practitioners may balk at the stringency of
          these guidelines, but as massage therapy becomes more integrated with other forms of health care,
          the risks of insufficient hygienic practices become more serious. </p>

        <h5 class="boldSubTitle"><span>A Standard Precautions Reminder</span></h5>

        <p>Standard precautions are a set of protocols that create some uniformity in how medical professionals limit
          contact with infectious agents in the working environment. They evolved from the “universal precautions”
          protocols developed by the CDC in 1987 to limit the risk of infection related specifically to blood.
          Standard precautions include guidelines on how to avoid exposure to all potentially infectious body fluids,
          as well as airborne pathogens when possible. The following fluids are specifically listed as potentially
          infectious: semen, vaginal secretions, breast milk, cerebrospinal fluid, synovial fluid, pleural fluid,
          pericardial fluid, amniotic fluid, blood, and blood-tinged saliva. Sweat is not usually described as a
          potentially infectious fluid, but in some circumstances, it can carry some potentially dangerous bacteria.
          Visit <a target="_blank" href="http://www.cdc.gov">www.cdc.gov</a> for a list of current standard precautions protocols.</p>


        <h5 class="boldSubTitle"><span>Don’t Dismiss Your Hand Washing</span></h5>

        <p>After extensive research comparing the benefits and risks of frequent hand washing with plain soap and water, antimicrobial soap, and alcohol-based gels, well-accepted recommendations for normal use and for health-care workers have been developed. Running warm water (not hot water, which raises the risk of skin irritation) plus plain soap for 30 seconds with all rings, bracelets, and watches removed is adequate for most everyday use. Special focus must be given to the fingernails and cuticles, where pathogens may not be removed without specific attention. Long sleeves are not recommended for health-care providers, because they can carry pathogens that cannot be removed with hand washing.</p>


        <h5 class="boldSubTitle"><span>The Best Way to Clean Hands</span></h5>

        <p>Washing with warm, running water and plain soap is always recommended to remove any visible or palpable dirt or fluids. It is preferable to dispense soap in liquid form, because bacteria can colonize bar soap. Rinsing so that water runs from the hands down the fingers and into the sink is preferable, so contaminated water does not run up toward the elbow.</p>

        <p>Using alcohol-based gel or foam according to manufacturers’ directions (which means using the amount prescribed and rubbing until the skin is dry) is often faster and more convenient than washing with soap and water. This is an effective antibacterial and antiviral mechanism, but it does not remove dirt, oil, or lotion from previous clients, and it is not effective against spore-forming bacteria. Hand cleaning with alcohol gel or foam is often recommended in medical settings where a trip to the sink for a full soap-and-water treatment is not feasible. Alcohol-soaked towelettes are specifically not recommended because their alcohol concentration isn’t high enough to be effective.</p>

        <p>Washing hands with water and antimicrobial soap is effective, but carries a higher risk for negative reactions in the form of allergies or contact dermatitis than washing with plain soap. No evidence supports the theory that antibacterial soap provides significant benefits compared to plain soap, but scientists have found that some common bacteria have developed resistance to triclosan, the substance used to limit bacterial activity in antimicrobial soaps.</p>


        <h5 class="boldSubTitle"><span>Other Hand Care</span></h5>

        <p>In addition to keeping hands and nails clean, massage therapists must be vigilant about the risk of open lesions. Hangnails that peel and fray can become portals of entry for serious infections. Hangnails must be kept short and controlled; a good pair of cuticle scissors and appropriate lotion can help with this.</p>

        <p>Any other open lesions on the hands must be covered during a massage. This can be done with a simple bandage—if it is in a place that doesn’t come in direct contact with a client and is changed with each session. All other lesions on the hands must be covered with a nitrile glove that is replaced with each client, and each time it contacts a potential fomite such as a doorknob, light switch, or volume button.</p>

        <p>Fingernails must be kept short, of course. In addition to the risk of damaging our clients’ skin, scrapings from the fingernails of health-care workers have been cultured to reveal large colonies of pathogens. Imagine getting a massage from someone with long nails, knowing what might be growing under there. How relaxing—or safe—would that be?</p>


        <h5 class="boldSubTitle"><span>Care of Surfaces and Equipment</span></h5>

        <p>The goal in a massage therapy workspace is to create an environment where nothing that one client touches directly or indirectly is touched by another client before it is cleaned. This means isolating table linens and other fabrics, and cleaning massage furniture, massage tools, lubricant dispensers, and any other items that might come into use during a session.</p>

        <p><em>Fabrics</em></p>

        <p>The fabrics that clients directly contact include linens on the massage table, face cradle covers, bolster and pillow covers, and the therapist’s clothing. Any fabric item that a client contacts should be laundered before another client touches it. Similarly, any item that a massage therapist touches during a session with one client should be cleaned or re-covered before it is used again.</p>

        <p>Therapists have some choices about their own clothing. Some wear aprons that can be changed with every appointment; this is appropriate as long as the client does not directly contact other articles of clothing. It is also possible to own several uniform shirts that can be changed between sessions. Aprons, uniform shirts, and other clothing items can be laundered with linens.</p>

        <p>Here are some important factors regarding laundry protocols:</p>

        <ul>

          <li>Professional laundering services use water that is 160°F (71.1°C) or above, with a minimum of 25 minutes of agitation to reduce microbial populations.</li>

          <li>Good antimicrobial effect is found with temperatures from 71°F to 77°F (21.6°C–25°C), if the detergent is span and used according to manufacturers’ directions.</li>

          <li>If bleach is added to the wash, it becomes most active at temperatures above 135°F (62.7°C). Most home water heaters heat water to 120°F–140°F (48.4°C–60°C), so bleach in the washing machine may not reach its full potential. The recommended amount of bleach is a ratio of 50–150 ppm (parts per million).</li>

          <li>Bleached laundry must be thoroughly rinsed to minimize irritation to users.</li>

          <li>Laundry must not be left damp for more than two hours.</li>

          <li>All laundry should be dried on high heat (160°F, 71.1°C). Ironing adds extra antimicrobial action, but this is probably not a practical suggestion for most massage therapists.</li>

          <li>Clean laundry must be packaged to keep it clean until its next use. It could be wrapped in plastic or stored in a closed, freshly disinfected container.</li>

        </ul>

        <p>Other fabric items include mattress pads, bolsters, pillows, blankets, and heating pad covers. Any of these should be laundered if a client touched it directly, but if the contact was through some other covering (for example, a mattress pad that is covered by a nonpermeable barrier), then laundering for every session is unnecessary. The exception to this rule is when there are signs of contamination (bleeding or other fluid seepage) that may penetrate through protective layers of fabric. If not soiled, the protocol for these items that are not directly touched by clients is to wash them once a week.</p>


        <h5 class="boldSubTitle"><span>Other Equipment</span></h5>

        <p>Massage tables and chairs must be cleaned with disinfectant between clients. This is especially important for face cradles, of course. The cleaning product should be at least an intermediate-level disinfectant. The CDC recommends a 10 percent bleach solution for high-touch surfaces; this is inexpensive and easily available. It is important to mix fresh solution frequently, however, as bleach solutions lose potency if they are not used promptly and if they are stored in translucent bottles. Bleach-infused wipes can be useful in this application, but it is important to read the labels for best results: Some of them require several minutes of exposure to be effective. Alcohol is specifically not recommended for cleaning surfaces because it evaporates too quickly; it works best with prolonged contact against targeted pathogens.</p>

        <p>Massage lubricants must be kept free from the risk of cross-contamination. Lubricants that are solid at room temperature (for example, beeswax, coconut oil) must be dispensed into individual containers and leftovers discarded so that double dipping never occurs. Liquid lubricants must be dispensed in bottles that are washed between every session. Bottles should be kept away from possibly contaminated surfaces, such as desktops or the floor.</p>

        <p>Hot or cold rocks and crystals may be the only massage tools that lend themselves to full sterilization, but disinfection is sufficient. Items that are not disposable, such as massage tools and hot and cold packs, must have their contacting surfaces disinfected every time they are used.</p>


        <h5 class="boldSubTitle"><span>The Massage Environment</span></h5>

        <p>Research indicates that fabrics such as curtains, carpeting, and upholstery are not significant sites of transmission for infectious agents, but they may harbor pet hair or dander that could cause an allergic reaction. For this reason, upholstery and carpets should be vacuumed frequently. Vinyl or leather upholstery can be cleaned with disinfectant. Any carpeting that gets wet can harbor bacteria and fungi; it should be replaced if it isn’t completely dry within a few hours. Hard floors should be washed regularly with detergent, but no particular benefit has been found in washing frequently with high-level disinfectants.</p>

        <p>Other surfaces that clients and therapists contact should also be cleaned frequently. These include doorknobs, bathroom fixtures, light switch plates, telephones, and coat racks or hooks. If a therapist uses a computer in the office, the keyboard may provide a rich growth medium for pathogens. This can be mitigated with antiseptic-soaked towelettes or keyboard covers that can be washed in the sink. Also, cash is not called “filthy lucre” for nothing; it is typically handled by numerous dirty hands and is an excellent vector for communicable diseases. Credit cards may be considered in this light as well.</p>

        <p>The guidelines suggested here may seem alarmist, but they are well within recommendations for health-care settings. As more people seek massage, and as new and spaner forms of pathogens develop, it is becoming increasingly important for massage therapists in any setting to create the most professional and safest environment possible.</p>

        <p><a target="_blank" href="http://www.massageandbodyworkdigital.com/i/72093-may-june-2012/38?">Adapted from <em>A Massage
          Therapist’s Guide to Pathology</em>, 2019, Books of Discovery, and&nbsp; "Hygiene Fact and Fiction," <em>Massage &amp; Bodywork</em>,
          by Ruth Werner
          </a></p>


      </main>
    </div> <!-- end Col -->

  </div> <!-- End Row -->

</div>
