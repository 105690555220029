<div class="container-lg">

  <div class="row justify-content-end my-3">

    <div class="col col-lg-6 col-md-6">
      <main [ngClass]="[Type]">
        <h1 class="text-light mt-3">{{ Type === 'favorite' ? 'Saved Pathologies' : 'Recent Pathologies' }} </h1>

        <div *ngIf="(Pathology$| async)?.length > 0" class="rowTest clearfix">
          <button type="button"
            class="btn btn-secondary float-right mt-2 mb-2 remove"
            (click)="Clear()">
           <span><fa-icon class="mr-1" [icon]="faTimes">Delete</fa-icon> Clear All {{ Type === 'favorite' ? 'Saved' : 'Recent' }}</span></button>
        </div>

        <div class="rowTest clearfix">

          <ul class="list-group">
            <li class="list-group-item transparent-card d-flex flex-row px-0 py-0" *ngFor="let item of Pathology$ | async; trackBy: trackBy" @pathology>
              <a class="title flex-fill px-3 py-3 " [routerLink]="['/pathology', item.alias]" >{{ item.title }}</a>
              <button [disabled]="!item.status" class="btn btn-sm btn-light float-right px-3 remove" (click)="Remove(item)">
                <span ><fa-icon [icon]="faTimes">Delete</fa-icon></span>
              </button>
            </li>
          </ul>
        </div>


      </main>
    </div>

  </div>

</div>
