import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Constantvars } from '../../../constantvars';

@Component({
  selector: 'app-diagnose',
  templateUrl: './diagnose.component.html',
  styleUrls: ['./diagnose.component.scss']
})
export class DiagnoseComponent implements OnInit {

  public vidURL: string;
  public vidJpg: string;
  public vidType: string;
  public autoplay: boolean;
  public AppConstants: any;
  public faChevronLeft = faChevronLeft;
  public pgTitle: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.vidType = Constantvars.vidType;
    this.autoplay = Constantvars.autoplay;

    this.vidURL = Constantvars.vidBucket + 'pocketpatho-Diagnose-FINAL/Default/HLS/pocketpatho-Diagnose-FINAL.m3u8';
    this.vidJpg = Constantvars.popaImgs + 'Ruth_vid_scope.jpg';
    this.pgTitle = 'Diagnose No, Refer Out Yes';
}
  ngOnInit(): void {
  }


}
