export class JwtToken {

  private _value: string;

  public get Value(): string {
    return this._value;
  }

  constructor(value: string) {
    this.Update(value);
  }

  public Update(value: string): void {
    if (value) {
      this._value = value;
      localStorage.setItem('JWT_TOKEN', value);
    } else {
      this._value = null;
      localStorage.removeItem('JWT_TOKEN');
    }
  }

  public valueOf(): string {
    return this._value;
  }

  public toString(): string {
    return this._value;
  }
}
