<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>

        <p class="mb-3 "><strong class="RuthSubTitle">By Ruth Werner, author of <em>A Massage Therapist’s Guide to Pathology</em></strong></p>


        <button [routerLink]="['/videos-and-resources']"
        [ngClass]="AppConstants.btnClassBackToReso "
        type="button">
          <fa-icon [icon]="faChevronLeft" class="mr-2"></fa-icon>Videos, Forms, and Resources
        </button>


        <p class="mt-3">Cautionary sites, or endangerments, are places where careless massage could damage some of the body’s most delicate tissues. </p>

          <p>This resource covers a basic list of cautionary sites, with two important caveats: </p>

          <ul>
            <li>Inappropriate and insensitive pressure can damage <em>any</em> kind of tissue—not just especially delicate ones;</li>
            <li>Good education and skill can allow us to work safely in areas that would otherwise be “off limits” to inexperienced practitioners. For instance, important work can be done in the axillary area, if we know how to avoid the brachial plexus and other vulnerable structures.</li>
          </ul>


          <p class="pr-xl-4">Generally speaking, we avoid causing injury at endangerment sites by using broad, flat, and light pressure in these areas—as opposed to more intrusive work that may injure or irritate delicate tissues. Identifying an area as an endangerment or cautionary site doesn’t mean it can’t be touched; it just has to be touched and incorporated into the massage with special care.</p>

          <p>We can classify cautionary sites by tissue type, or by location.</p>



          <h3 class="mt-4 mb-0 clearfix">Cautionary Sites by Tissue Type</h3>
          <hr class="mt-1">


          <h6 class="mt-4"><strong>Blood vessels</strong></h6>

          <p>These include the common carotid artery, the great saphenous vein, and others. Arteries generally run deeper than veins, and are less vulnerable to injury. However, an artery can be compressed anywhere that a pulse can be felt, and this must be avoided for more than a few moments at a time.</p>



          <h6 class="mt-4"><strong>Bony prominences</strong></h6>

          <p>These include the floating ribs, the xyphoid process, and others.</p>


          <h6 class="mt-4"><strong>Lymph nodes</strong></h6>

          <p>These include the cervical and axillary nodes and others. Lymph nodes are rarely an issue in massage therapy unless they are enlarged or painful, which may indicate an infection or other problem.</p>


          <h6 class="mt-4"><strong>Nerves</strong></h6>

          <p>These include the brachial plexus, the sciatic nerve, and others. Nerves are identified as possible endangerment sites in areas where they are easily compressed on other tissues.</p>


          <h6 class="mt-4"><strong>Organs</strong></h6>

          <p>These include the eyes, liver, kidneys, ovaries, and others. </p>


          <h3 class="mt-4 mb-0 clearfix">Cautionary Sites By Location </h3>
          <hr class="mt-1 test clearfix">

          <h6 class="mt-4"><strong>Head and face</strong></h6>

          <ul>
            <li>Eyes (watch for contact lenses too)</li>

            <li>Nerve pathways</li>

            <li>Mental, infraorbital, supraorbital foramina (trigeminal nerve)</li>

            <li>Anterior to mastoid process (facial nerve, trigeminal nerve, styloid of temporal bone)</li>

            <li>Occipital ridge (occipital nerve)</li>

          </ul>



          <h6 class="mt-4"><strong>Anterior trunk and neck</strong></h6>

          <ul>

            <li>Anterior triangle of neck
              (defined by medial edges of the sternocleidomastoid muscles and the inferior aspect of the mandible)

              <ul class="subList">

              <li>Common carotid artery</li>

              <li>Jugular vein</li>

              <li>Hyoid bone</li>

              <li>Thyroid cartilage, larynx</li>

              <li>Trachea </li>

              <li>Thyroid gland  </li>

              </ul>
          </li>


          <li>Posterior triangle of the neck (defined by lateral sternocleidomastoid, medial trapezius, and middle 1/3 of the clavicle)

            <ul class="subList">

            <li>Spinal accessory nerve</li>

            <li>Brachial plexus trunks</li>

            <li>External jugular</li>

            <li>Cervical lymph nodes </li>
            </ul>
         </li>


          <li>Notch between the lateral pectoralis major and medial deltoid, inferior to the clavicle

            <ul class="subList">
            <li>Brachial plexus</li>
            <li>Subclavian artery and vein</li>
            </ul>
          </li>

          <li>Abdomen

            <ul class="subList">

            <li>Xyphoid process (a fracture can damage the liver)</li>

            <li>Floating ribs</li>

            <li>Liver </li>

            <li>Spleen </li>

            <li>Colon </li>

            <li>Ovaries </li>
            </ul>
          </li>
        </ul>

      <h6 class="mt-4"><strong>Posterior trunk </strong></h6>
        <ul>

          <li>Kidneys </li>

          <li>Spinous processes</li>

        </ul>


        <h6 class="mt-4"><strong>Upper extremity</strong></h6>
        <ul>

          <li>Axilla

            <ul class="subList">

              <li>Brachial plexus, axillary nerve</li>

              <li>Axillary artery and vein</li>

              <li>Axillary lymph nodes</li>
            </ul>
          </li>


          <li>Posterior side of the upper arm
            <ul class="subList">

              <li>Radial nerve</li>
            </ul>
          </li>

          <li>Medial side of the upper arm
            <ul class="subList">

              <li>Cephalic and basilic veins</li>

              <li>Brachial plexus nerves (musculocutaneous, radial, median, ulnar nerves)</li>
            </ul>
          </li>

          <li>Cubital tunnel
            <ul class="subList">

              <li>Ulnar nerve</li>
            </ul>
          </li>

          <li>Carpal tunnel
            <ul class="subList">

              <li>Median nerve </li>
              </ul>

          </li>
        </ul>


        <h6 class="mt-4"><strong>Lower extremity</strong></h6>
        <ul>

          <li>Femoral triangle
            <ul class="subList">

              <li>Femoral nerve</li>

              <li>Femoral artery and vein</li>

              <li>Inguinal lymph nodes</li>
            </ul>

          </li>

          <li>Sciatic notch
            <ul class="subList">

              <li>Sciatic nerve</li>
            </ul>
          </li>

          <li>Popliteal fossa
            <ul class="subList">

              <li>Common fibular nerve</li>

              <li>Popliteal artery and vein</li>

              <li>Tibial nerve</li>
            </ul>

          </li>

          <li>Medial lower leg
              <ul class="subList">

                <li>Great saphenous vein</li>

                <li>Tarsal tunnel (tibial nerve, and tibial artery and vein)</li>
              </ul>
          </li>

          <li>Posterior lower leg
            <ul class="subList">

                <li>Small saphenous vein</li>
              </ul>
          </li>

          <li>Lateral lower leg
          <ul class="subList">

            <li>Common peroneal nerve</li>
            </ul>
          </li>

          </ul>


      </main>
    </div> <!-- end Col -->

  </div> <!-- End Row -->

</div>
