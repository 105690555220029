import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { ConnectionService } from './connection.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  public get Loader$(): Observable<boolean> {
    return this.loader$;
  }

  private loader$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private userService: UserService,
    private connectionService: ConnectionService,
    private router: Router) {
  }

  public canActivate(): Observable<boolean> {
    this.loader$.next(true);

    return this.userService.UserState$.pipe(
      tap(user => {console.log('authgaurd', user)}),
      withLatestFrom(this.connectionService.monitor()),
      filter(([user, connection]) => {
        // Don't use cached response when online
        if (connection && user && user.fromCache) {
          return false;
        }

        return true;
      }),
      map(([user]) => user),
      map(user => {
      this.loader$.next(false);
      console.log('authgaurd', user);

      if (user === null || user.IsLoggedIn() === false) {
        this.router.navigate(['login']);
        return false;
      }

      return true;
    }));
  }

}
