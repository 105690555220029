import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pathology } from 'src/app/models/pathology.model';
import { PathologyService, PathologyTitleMatch } from 'src/app/services/pathology.service';
import { RecentPathology, RecentPathologyService } from 'src/app/services/recent-pathology.service';

@Component({
  templateUrl: './pathology-search.component.html',
  styleUrls: ['./pathology-search.component.scss']
})
export class PathologySearchComponent implements OnInit {

  public Pathologies$: Observable<Pathology[]>;
  public search$ = new BehaviorSubject<[string, boolean]>(null);

  constructor(
    private pathologyService: PathologyService
  ) {
  }

  public ngOnInit(): void {
    this.Pathologies$ = combineLatest([this.search$, this.pathologyService.PathoNodes$]).pipe(
      map(([search, nodes]) => {

        if (!search) {
          return [];
        }

        const [term, deep] = search;
        let keywords: string[];

        if (term === null || term.length < 2) {
          return [];
        }

        if (deep) {
          keywords = term.split(/[\,\s]+/).map(word => word.replace(/[^\w\d]/, ''));
        }

        return nodes.filter(node => {
          let match = PathologyTitleMatch(term, node);

          if (deep) {
            let keywordMatch = false;
            for (const word of keywords) {
              keywordMatch = node.keywords.some(item => item.toString().indexOf(word) !== -1);
              if (keywordMatch) {
                break;
              }
            }

            match = match || keywordMatch;
          }

          return match;
        });
      })
    );
  }

}
