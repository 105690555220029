<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <p class="mb-3 "><strong class="RuthSubTitle">By Ruth Werner, author of <em>A Massage Therapist’s Guide to Pathology</em></strong></p>

        <button [routerLink]="['/videos-and-resources']"
        [ngClass]="AppConstants.btnClassBackToReso "
        type="button">
          <fa-icon [icon]="faChevronLeft" class="mr-2"></fa-icon>Videos, Forms, and Resources
        </button>


        <!-- <h1 class="mt-1 mb-2 ">Massage Therapy for People with Cancer </h1> -->


        <p class="mt-3">A long-held tradition in massage therapy education is that some types of rigorous bodywork carry the risk of aiding metastasis by boosting blood and lymphatic flow. However, current research does not support this theory. Therefore, it is far-fetched to propose that a 60-minute massage could contribute to that process any more significantly than a brisk walk around the block or a long hot shower. Nonetheless, it is obviously inappropriate to manipulate a diagnosed tumor or any undiagnosed swelling or thickening of tissue.</p>

            <p>Massage therapy for persons undergoing cancer treatment has a vital and useful role. Symptoms of cancer and cancer treatment that are common in most patients include pain, anxiety, nausea, fatigue, and depression. All of these can be mitigated with appropriate massage therapy. Constipation, altered body image, and poor sleep are other common problems that can be improved with massage therapy or bodywork. Manual therapies in various forms are being researched in all of these contexts. This body of knowledge is in constant flux, and new findings will continue to influence the role of massage therapy in cancer care. </p>

            <p>The benefits of massage therapy are well accepted by medical professionals, and highly valued by patients. Perhaps most important of all possible benefits, massage therapy answers a basic human need: compassionate, caring, informed touch offered at a time when many cancer patients feel isolated, anxious, and dehumanized. </p>

            <p>It is important to bear in mind that the complications associated with cancer and various cancer treatments can have serious implications for the choice of bodywork modalities, especially when multiple treatments are employed. One way to clarify choices about massage for cancer patients is to determine whether certain risks are brought about by the cancer itself or by cancer treatment.</p>



            <h5 class="boldSubTitle"><span>Massage Therapy Risks Related to Cancer</span></h5>


            <ul>
              <li><em>Tumor sites.</em> Do not let massage disrupt any site where tumors or undiagnosed lesions are located close to the surface of the body. Similarly, a person with any kind of pelvic or abdominal cancer is not a good candidate for direct or deep abdominal techniques.</li>
              <li><em>Bone involvement.</em> When cancer metastasizes to bones, they can become brittle and unstable. This can make a person vulnerable to fractures. Bones are a common site of metastasis, especially the vertebrae, ribs, and pelvis. The risk of bony involvement is difficult to overstate, especially for some cancer types; this must be rigorously screened.</li>
              <li><em>Vital organ involvement.</em> A client who has cancer or metastasis in a vital organ (this includes the lungs, liver, brain, kidneys, and heart) may have compromised function of any of those organs. This risk must be evaluated to predict if, or how well, the patient may be able to adapt to bodywork. Accommodations might include changing positions to allow easier breathing, taking care with pressure if blood clotting is an issue, and adding help to get on and off the table if any risk of falling is present.</li>
              <li><em>Deep vein thrombosis.</em> A potential complication of both cancer and cancer treatment, the risk of deep vein thrombosis is a red flag for massage. This risk may persist for up to six months after treatment is concluded.</li>
            </ul>


            <h5 class="boldSubTitle"><span>Massage Therapy Risks Related to Cancer Treatment</span></h5>

            <p>Cancer treatment is often aggressive and involves side effects that can seem more extreme or even hazardous than the cancer itself. It is crucial for massage therapists working with this population to be well informed about making appropriate adaptations. Among the most common side effects that can develop with a variety of cancer treatments are low white blood cell count (which reduces resistance to infection), low platelet count (leading to easy bruising and bleeding), and factors that contribute to the risk of venous thromboembolism. </p>



            <ul>

            <li><em>Surgical complications.</em> Several complications of cancer surgery can have implications for massage. Postsurgical infection obviously contraindicates massage until the client is out of danger. Constipation is a frequent postsurgical complaint, but intrusive abdominal massage may be inappropriate, depending on the cancer site. Any constipation related to an intestinal obstruction or abdominal tumor contraindicates massage. Experts suggest waiting at least six weeks after radiation to the abdomen to do any work here. In the absence of these cautions, gentle work to the abdomen can give significant relief to patients who are constipated. Medical devices may be present after surgery, including ports, peripherally inserted central catheters (PICC lines), drains, or ostomies. Inguinal catheters in particular increase clotting risk. Devices should be locally avoided, and the client must be positioned in ways that minimize the risk of their disruption.</li>

            <li><em>Edema.</em> Edema is the accumulation of fluid in the interstitial spaces, often because of trauma or venous insufficiency. Edema in the context of cancer can accumulate where normal blood and lymphatic circulation is obstructed because of scarring. Persistent edema can become thick and pitted, but it can be successfully treated if obstructions are addressed.</li>

            <li><em>Lymphedema.</em> Lymphedema is the accumulation of protein-rich fluid in interstitial spaces as a result of lymphatic system dysfunction. (This distinguishes it from simple edema, described above.) In lymphedema, the chemistry of interstitial fluid is such that when it doesn’t flow freely into nearby lymphatic vessels, it attracts water. In this way, a minor level of fluid retention can become a significant problem in a short period. The swelling with lymphedema can be relatively mild or very extreme. Lymphedema can develop because cancer cells block or damage lymph nodes, or because cancer treatments (specifically surgery or radiation) interfere with lymphatic system function. Massage therapists with special training can help to treat lymphedema, but uneducated touch here can make a bad situation much worse. For a client with a history of lymphedema, only the lightest touch or holding, for anywhere within the affected quadrant of the body, is appropriate—unless the practitioner has specialized skills. Various kinds of manual therapies have been developed to address lymphedema, and they employ extensive knowledge of the anatomy and physiology of the lymphatic system to maximize benefits while minimizing risks.</li>

            <li><em>Radiation side effects.</em> Radiation can create several problems. When it is applied by an external machine, the skin at the entry and exit sites may become thin, red, blistered, and irritated. If radioactive pellets have been implanted, the patient is typically told to avoid contact with others until the pellets have been removed or the radiation has lost its potency. Radiation can irritate the gastrointestinal tract, leading to nausea, vomiting, and diarrhea; this calls for our best soothing massage—with the caveat that we don’t treat the abdomen directly until radiation is complete and the tissue is healing. Bone marrow suppression can lead to anemia, fatigue, poor clotting capacity, and susceptibility to infection. Irradiated lymph nodes can be damaged, leading to a risk of lymphedema. Finally, externally or internally applied radiation can cause fatigue that is extreme enough to interfere with fulfilling a treatment series.</li>

            <li><em>Chemotherapy side effects.</em> Chemotherapeutic drugs may be administered topically, orally, or through intravenous drips. They often suppress bone marrow activity and may lead to anemia, an elevated risk of infection, and clotting problems. Anemia causes fatigue and feeling cold; massage therapy needs to accommodate for these side effects. Low platelet counts call for accommodations to avoid inadvertent capillary injury and bruising. Gastrointestinal tract irritation, mouth sores, hand-foot syndrome (redness, irritation, pain, and blistering of the palms and soles), and hair loss are other side effects of drugs that kill fast-growing cells. Other complications of chemotherapy include neuropathy, constipation, skin rashes, and mood changes. Some of these are so serious that they may impact patients’ ability to finish treatment (and therefore their chances of survival). Massage therapy may help to address fatigue and neuropathy to improve treatment tolerance and make chemotherapy less taxing. Some varieties of chemotherapy are eliminated through the skin. It may not be appropriate for a patient to be extensively touched skin-to-skin during this process, but gloves can be used. In many hospital settings, the risk of infection among immune-compromised patients is considered to be so high that using gloves is a standard protocol regardless of chemotherapy type. </li>

            <li><em>Other complications of treatment. </em>Other cancer treatments may also hold cautions for massage. Hormone treatments may increase the risk of blood clots, targeted therapies may cause fatigue and flulike symptoms, cryotherapy can leave irritated areas on the skin, and so on. No matter what kind of cancer or cancer treatments a person is going through, it is vital for massage therapists to communicate with the rest of the health-care team to provide the best benefits that bodywork has to offer with minimal risks.</li>

          </ul>


            <h5 class="boldSubTitle"><span>Massage Therapy Benefits for Cancer Patients</span></h5>

            <p>For generations, massage therapists were taught that cancer and massage therapy were incompatible because the risks of spreading the disease outweighed any possible benefit. In the 1990s some brave researchers challenged that conventional wisdom, by investigating whether massage therapy might have any benefits to safely offer this population—and more research on this topic accumulates every year. While the results of the studies have some variations, findings suggest that expertly performed massage for cancer patients can do the following:</p>


            <ul>

            <li>Improve sleep</li>

            <li>Increase appetite</li>

            <li>Relieve constipation</li>

            <li>Improve mood</li>

            <li>Reduce anxiety</li>

            <li>Decrease depression</li>

            <li>Alleviate pain</li>
          </ul>


            <p>Most of these benefits have been explored in the context of immediate treatment-related challenges such as fatigue, nausea, and anxiety. However, massage therapy can also be helpful for cancer patients who have completed treatment, but who live with long-term consequences. Skilled bodywork practitioners may be able to help with many rehabilitation challenges. Manual therapies can address tissue damage related to radiation burns, and it can improve range of motion restrictions and pain related to post-surgical scarring. Axillary cording or axillary web syndrome, a common complication of breast cancer treatment, can be successfully addressed with skilled massage therapy. Skilled practitioners can also address lymphedema to reduce swelling and pain. </p>

            <p>However, all of this must happen in the context of carefully thought out and applied appropriate accommodations, including decisions about the timing and duration of sessions; positioning and bolstering; depth, location, and pacing of strokes; even the temperature of the room. All of these decisions must be informed by the client’s challenges and goals; their type of cancer and treatment process; and input from their oncologist and other health care providers.</p>

            <p>In short, oncology massage therapy is a complex undertaking that requires advanced education. </p>

            <p>Such training will provide specific guidance on gathering relevant information, anticipating appropriate bodywork modifications, and doing the critical thinking necessary to make the best choices for clients who are also cancer patients.</p>

            <p>This article was adapted from <em>A Massage Therapist’s Guide to Pathology</em> by Ruth Werner.</p>


      </main>
    </div> <!-- end Col -->

  </div> <!-- End Row -->

</div>
