import { Component, OnInit } from '@angular/core';
import { Constantvars } from '../../../constantvars';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-intake',
  templateUrl: './intake.component.html',
  styleUrls: ['./intake.component.scss']
})
export class IntakeComponent implements OnInit {
  public vidURL: string;
  public vidJpg: string;
  public vidType: string;
  public autoplay: boolean;
  public AppConstants: any;
  public faChevronLeft = faChevronLeft;
  public pgTitle: string;

  constructor() {
      this.AppConstants = Constantvars;
      this.vidType = Constantvars.vidType;
      this.autoplay = Constantvars.autoplay;

      this.vidURL = Constantvars.vidBucket + 'pocketpatho-Intake-FINAL/Default/HLS/pocketpatho-Intake-FINAL.m3u8';
      this.vidJpg = Constantvars.popaImgs + 'Ruth_vid_intake.jpg';
      this.pgTitle = 'How to Do a Proper Health Intake';

  }

  ngOnInit(): void {
  }

}
