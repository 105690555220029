import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: ['./favorite-button.component.scss']
})
export class FavoriteButtonComponent implements OnInit {

  @Input()
  public isFavorite: boolean = false;

  @Output()
  public toggle: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('btn')
  public btn: ElementRef<HTMLButtonElement>;

  public faBookmarkSolid = faBookmarkSolid;
  public faBookmarkRegular = faBookmarkRegular;

  constructor() { }

  public ngOnInit(): void {
  }

  public ToggleFaveStar(status: boolean): void {
    this.toggle.emit(status);
    this.btn.nativeElement.blur();
  }

}
