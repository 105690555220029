import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Constantvars } from '../../../constantvars';

@Component({
  selector: 'app-massage-and-cancer',
  templateUrl: './massage-and-cancer.component.html',
  styleUrls: ['./massage-and-cancer.component.scss']
})
export class MassageAndCancerComponent implements OnInit {

  public AppConstants: any;
  public faChevronLeft = faChevronLeft;
  public pgTitle: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.pgTitle = 'Massage Therapy for People with Cancer';
  }

  ngOnInit() {
  }

}
