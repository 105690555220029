<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{ pgTitle }} </h1>
        <hr>

        <button
        (click)="back()"
        [ngClass]="AppConstants.btnClassBackToReso"
        type="button">
          <fa-icon [icon]="faChevronLeft" class="mr-2"></fa-icon>{{ prevTitle }}
        </button>
        <!-- [routerLink]="['/videos-and-resources']" -->



        <app-video
        class="card-img-top"
        [Poster]="this.vidJpg"
        [source]="[{ src: this.vidURL, type: this.vidType }]"
        [AutoPlay]="AppConstants.autoplay"
        ></app-video>
      </main>

    </div>
  </div>
</div>
