<div class="row justify-content-end">
  <div class="col-lg-6 col-md-6">
    <h1 class="text-light mt-4">Search Pathologies</h1>
    <app-search-box (pathologySearch)="search$.next($event)"></app-search-box>
  </div>
</div>

<div class="row justify-content-end">
  <div class="col-lg-6 col-md-6">
    <a class="text-light mb-3 float-right underline" [routerLink]="['/list']">
      List All Pathologies (A-Z)
    </a>
  </div>
</div>

<div class="row justify-content-end">
  <div class="col-lg-6 col-md-6">
    <ng-container *ngIf="Pathologies$ | async as Pathologies">
      <app-pathology-short class="mb-3" *ngFor="let pathology of Pathologies" [Pathology]="pathology"></app-pathology-short>
      <div *ngIf="Pathologies && Pathologies.length === 0 && search$.value && search$.value[0] && search$.value[0].length > 0" class="jumbotron">
        <p>No pathologies found...</p>
      </div>
    </ng-container>
  </div>
</div>
