export class Constantvars {
  public static vidType = 'application/x-mpegURL';
  public static vidBucket = 'https://videos.abmp.com/public/';
  public static popaImgs = 'https://cdn.abmp.com/public/image/pocket-pathology/';
  public static autoplay = true;

  public static pgContainer = 'pgcontainer container-lg mt-sm-0 pt-sm-0 my-lg-3 my-md-3 mt-sm-4';
  public static pgRowJustifyRight = 'row justify-content-end';
  public static pgMainCol = 'pgmaincol col-lg-8 col-md-12 col-sm-10 col-xs-10 mt-md-4 px-3 py-3';
  public static btnClassBackToReso = 'btn btn-light btn-link btn-block font-weight-bold clearfix text-left px-3 py-3';

}
