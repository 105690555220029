<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <hr>

        <accordion #accordion [isAnimated]="true" [closeOthers]="false">

          <accordion-group #Videos [isOpen]="this.isAccordOpen" >
            <button class="btn btn-link btn-block accHeader font-weight-bold clearfix text-left"
            accordion-heading type="button">
              <fa-icon [icon]="Videos.isOpen ? faChevronUp : faChevronDown"></fa-icon> <span class="title_text ml-2">Videos</span>
            </button>

            <div class="row vids mb-2">

              <div class="col-12 col-lg-6 col-md-12 pr-0 mb-4">
                <h6 class="mt-2">Welcome to Pocket Pathology</h6>

                <a [routerLink]="['/resources/video-welcome']"
                alt="Play Video"
                class="d-sm-block btn-block holder vidLink" >
                  <img [src]="this.popaImgs + 'Ruth-vid-welcome-vid342pxb.jpg' "  class="img-fluid vid_poster" />
                  <button type="button"
                  class="btn btn-secondary ng-tns-c85-2 float-md-right mt-2 mb-2 font-weight-bold text-light">Play Video</button>
                </a>

              </div>

              <div class="col-12 col-lg-6 pr-0 mb-4">
                <h6 class="mt-md-2 mt-sm-4">Diagnose No, Refer Out Yes </h6>

                  <a [routerLink]="['/resources/video-diagnose']"
                  alt="Play Video"
                  class="d-sm-block btn-block holder vidLink" >
                    <img [src]="this.popaImgs + 'Ruth-vid-scope-vid342pxb.jpg' "  class="img-fluid vid_poster" />
                    <button type="button"
                    class="btn btn-secondary ng-tns-c85-2 float-md-right mt-2 mb-2 font-weight-bold text-light">Play Video</button>
                  </a>

              </div>

              <div class="col-12 col-lg-6 pr-0  mb-4">
                <h6 class="mt-md-2 mt-sm-4">How to Do a Proper Health Intake</h6>

                <a [routerLink]="['/resources/video-intake']"
                alt="Play Video"
                class="d-sm-block btn-block holder vidLink" >
                  <img [src]="this.popaImgs + 'Ruth-vid-intake-vid342pxb.jpg' "  class="img-fluid vid_poster" />
                  <button type="button"
                  class="btn btn-secondary ng-tns-c85-2 float-md-right mt-2 mb-2 font-weight-bold text-light">Play Video</button>
                </a>

              </div>

            </div>
          </accordion-group>

          <accordion-group #forms [isOpen]="this.isAccordOpen" >
            <button class="btn btn-link btn-block accHeader font-weight-bold clearfix text-left" accordion-heading type="button">
              <fa-icon [icon]="forms.isOpen ? faChevronUp : faChevronDown"></fa-icon> <span class="title_text ml-2">Forms</span>
            </button>
            <ul>
              <li>
                <a href="https://cdn.abmp.com/public/image/pocket-pathology/pdfs/body-map-for-clients.pdf" target="_blank">
                  Body Map for Clients
                </a>
              </li>
              <li>
                <a href="https://cdn.abmp.com/public/image/pocket-pathology/pdfs/health-intake.pdf" target="_blank">
                  Health Information
                </a>
              </li>
              <li>
                <a href="https://cdn.abmp.com/public/image/pocket-pathology/pdfs/health-information-print.pdf" target="_blank">
                  Health Information (Print)
                </a>
              </li>
              <li>
                <a href="https://cdn.abmp.com/public/image/pocket-pathology/pdfs/health-status-update.pdf" target="_blank">
                  Health Status Update
                </a>
              </li>
              <li>
                <a href="https://cdn.abmp.com/public/image/pocket-pathology/pdfs/screening-questionnaire.pdf" target="_blank">
                  Screening Questionnaire
                </a>
              </li>
              <li>
                <a href="https://cdn.abmp.com/public/image/pocket-pathology/pdfs/screening-questionnaire-print.pdf" target="_blank">
                  Screening Questionnaire (Print)
                </a>
              </li>
              <li>
                <a href="https://cdn.abmp.com/public/image/pocket-pathology/pdfs/soap-notes.pdf" target="_blank">
                  SOAP Notes
                </a>
              </li>
            </ul>
          </accordion-group>

          <accordion-group #Resources [isOpen]="this.isAccordOpen" >
            <button class="btn btn-link btn-block accHeader font-weight-bold clearfix text-left" accordion-heading type="button">
              <fa-icon [icon]="Resources.isOpen ? faChevronUp : faChevronDown"></fa-icon> <span class="title_text ml-2">Resources</span>
            </button>
            <ul>
              <li>
                <a [routerLink]="['/resources/emergency']" >
                  What To Do In An Emergency
                </a>
              </li>
              <li>
                <a [routerLink]="['/resources/endangerment-sites']" >
                  Endangerment Sites
                </a>
              </li>
              <li>
                <a [routerLink]="['/resources/massage-and-cancer']" >
                  Massage and Cancer
                </a>
              </li>
              <li>
                <a [routerLink]="['/resources/hygiene-protocols']" >
                 Hygiene and Clean Practice Protocols
                </a>
              </li>
            </ul>
          </accordion-group>

        </accordion>
        <br />

    </main>
  </div>

</div>
