import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { FavoritePathologyService } from '../../services/favorite-pathology.service';
import { RecentPathologyService } from '../../services/recent-pathology.service';
import { ISavedPathology } from '../../services/saved-patho.service';

@Component({
  templateUrl: './saved-pathology.component.html',
  styleUrls: ['./saved-pathology.component.scss'],
  animations: [
    trigger('pathology', [
      transition(':leave', [
        animate('.3s', style({ opacity: 0}))
      ])
    ])
  ]
})
export class SavedPathologyComponent implements OnInit, OnDestroy {

  public Pathology$: Observable<ISavedPathology[]>;
  public Type: 'favorite' | 'recent';

  public faTimes = faTimes;

  private sink: SubSink = new SubSink();

  constructor(
    private recent: RecentPathologyService,
    private favorite: FavoritePathologyService,
    private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.sink.sink = this.route.data.subscribe(data => {
      this.Type = data.type;
      this.Pathology$ = this[this.Type].Sorted$().pipe(
        map(pathologies => {
          const results = pathologies.filter(pathology => pathology.status );
          console.log('results', results);
          return (this.Type === 'recent') ? results.slice(0, 20) : results;
        })
      );
    });
  }

  public ngOnDestroy(): void {
    this.sink.unsubscribe();
  }

  public Remove(item: ISavedPathology): void {
    this[this.Type].Remove(item);
  }

  public Clear(): void {
    this[this.Type].Clear().subscribe();
  }

  public trackBy(index: number, item: ISavedPathology) {
    return item.nid;
  }

}
