import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Constantvars } from '../../constantvars';

@Component({
  selector: 'app-add-to-home-screen',
  templateUrl: './add-to-home-screen.component.html',
  styleUrls: ['./add-to-home-screen.component.scss']
})
export class AddToHomeScreenComponent implements OnInit {
  public AppConstants: any;
  public pgTitle: string;
  public popaImgs: string;

  deviceInfo = null;
  public OsCookieValue = 'UNKNOWN';
  public deviceOS: string;
  public IOSandMobi: boolean;
  public IosMobiSafari: boolean;
  public devicebrowser: string;
  public browserVersion: string;
  public browserMainVersion: number;

  public isMobi: boolean;
  public isTablet: boolean;
  public MacTablet: boolean;
  public isAndroid: boolean;
  public isIOS: boolean;

  constructor(
    private dd: DeviceDetectorService,
  ) {
    this.AppConstants = Constantvars;
    this.popaImgs = Constantvars.popaImgs;
    this.pgTitle = 'How To Install Pocket Pathology';
  }

  public ngOnInit(): void {
    this.deviceInfo = this.dd.getDeviceInfo();
    this.isMobi = this.dd.isMobile();
    this.isTablet = this.dd.isTablet();
    this.deviceOS = this.deviceInfo.os;
    this.devicebrowser = this.deviceInfo.browser;

    this.browserVersion = this.deviceInfo.browser_version;
    this.browserMainVersion = parseInt(this.browserVersion.split('.')[0], 10) || 0;

    // check IOS/Android condition
    if ((this.deviceOS === 'Mac') && (this.isTablet === true)) {
      this.MacTablet = true;
    }
    if ((this.deviceOS === 'iOS')) {
      this.isIOS = true;
      this.isAndroid = false;
    } else if ((this.deviceOS === 'Android')) {
      this.isAndroid = true;
      this.isIOS = false;
    } else {
      this.isAndroid = false;
      this.isIOS = false;
    }

  }
}
