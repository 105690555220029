import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RecentPathology, RecentPathologyService } from 'src/app/services/recent-pathology.service';
import { PathologyService } from 'src/app/services/pathology.service';

@Component({
  templateUrl: './pathology.component.html',
  styleUrls: ['./pathology.component.scss']
})
export class PathologyComponent implements OnInit {

  public recent: Observable<RecentPathology[]>;

  public Loading$: Observable<any>;

  public ModalHasBeenShown: boolean = false;

  constructor(
    private pathologyService: PathologyService,
    private recentService: RecentPathologyService) {
      if (localStorage instanceof Storage) {
        const mhbs = localStorage.getItem('ModalHasBeenShown');
        if (mhbs) {
          try {
            this.ModalHasBeenShown = JSON.parse(mhbs);
          } catch {
          }
        }
      }
  }

  public ngOnInit(): void {
    this.recent = this.recentService.State$();
    this.Loading$ = this.pathologyService.PathoNodes$;
  }
}
