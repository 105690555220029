<div class="row justify-content-end">
  <div class="col-lg-6 col-md-6">

    <h1 class="text-light mt-4">Pathology List</h1>

    <div class="row justify-content-center mt-1 mb-2">
      <div class="d-flex">
        <nav class="btn-toolbar" role="toolbar" aria-label="Jump List">
          <div class="btn-group mr-2" role="group" aria-label="Alphabet">
            <button type="button" class="btn btn-secondary" (click)="filter('A', 'B', 'C')">A-C</button>
            <button type="button" class="btn btn-secondary" (click)="filter('D', 'E', 'F', 'G', 'H')">D-H</button>
            <button type="button" class="btn btn-secondary" (click)="filter('I', 'J', 'K', 'L', 'M', 'N', 'O', 'P')">I-P</button>
            <button type="button" class="btn btn-secondary" (click)="filter('Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z')">Q-Z</button>
          </div>
          <div class="btn-group mr-2" role="group" aria-label="Navigation Link">
            <button type="button" class="btn btn-secondary" (click)="filter()">All</button>
            <button type="button" class="btn btn-secondary" [routerLink]="['/']">Search</button>
          </div>
        </nav>
      </div>
    </div>

    <app-pathology-short class="mb-3" *ngFor="let pathology of Pathologies$ | async" [Pathology]="pathology"></app-pathology-short>
  </div>
</div>
