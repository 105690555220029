import { Component, OnInit } from '@angular/core';
import { Constantvars } from '../../constantvars';

@Component({
  selector: 'app-buy-the-book',
  templateUrl: './buy-the-book.component.html',
  styleUrls: ['./buy-the-book.component.scss']
})
export class BuyTheBookComponent implements OnInit {

  public AppConstants: any;
  public pgTitle: string;
  public popaImgs: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.popaImgs = Constantvars.popaImgs;
    this.pgTitle = 'Buy The Book';
   }

  public ngOnInit(): void {
  }

}
