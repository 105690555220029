import { Component, OnInit, Input } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { faBookmark as faBookmarkSolid, faHistory, faUndo, faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class MenuDropdownComponent implements OnInit {

  @Input()
  public ButtonClass: Array<string>;

  @Input()
  public ButtonText: string = null;

  @Input()
  public Placement: 'top' | 'bottom' = 'bottom';

  public faBars = faBars;
  public faHistory = faHistory;
  public faUndo = faUndo;
  public faBookmarkSolid = faBookmarkSolid;

  constructor() { }

  public ngOnInit(): void {
  }

}
