import { Component, Inject, OnInit } from '@angular/core';

@Component({
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  public vidSourceIntro = 'https://videos.abmp.com/public/pocketpatho-Long-Intro/Default/HLS/pocketpatho-Long-Intro.m3u8';

  constructor() {
  }

  public ngOnInit(): void {
  }

}
