import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FavoriteButtonComponent } from './components/favorite-button/favorite-button.component';
import { ModalComponent } from './components/modal/modal.component';
import { PathologyShortComponent } from './components/pathology-short/pathology-short.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { VideoComponent } from './components/video/video.component';
import { AnchorDirective } from './directives/anchor.directive';
import { HeaderNavComponent } from './layout/header/header-nav/header-nav.component';
import { HeaderComponent } from './layout/header/header.component';
import { MenuDropdownComponent } from './layout/menu-dropdown/menu-dropdown.component';
import { NavBottomComponent } from './layout/nav-bottom/nav-bottom.component';
import { AboutPopaComponent } from './pages/about-popa/about-popa.component';
import { AboutRuthComponent } from './pages/about-ruth/about-ruth.component';
import { AddToHomeScreenComponent } from './pages/add-to-home-screen/add-to-home-screen.component';
import { BuyTheBookComponent } from './pages/buy-the-book/buy-the-book.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { HelpComponent } from './pages/help/help.component';
import { LoginFormComponent } from './pages/login/login-form/login-form.component';
import { LoginComponent } from './pages/login/login.component';
import { PathologyDetailsComponent } from './pages/pathology/pathology-details/pathology-details.component';
import { PathologyListComponent } from './pages/pathology/pathology-list/pathology-list.component';
import { PathologySearchComponent } from './pages/pathology/pathology-search/pathology-search.component';
import { PathologyComponent } from './pages/pathology/pathology.component';
import { EndangermentSitesComponent } from './pages/resources/endangerment-sites/endangerment-sites.component';
import { HygieneProtocolsComponent } from './pages/resources/hygiene-protocols/hygiene-protocols.component';
import { MassageAndCancerComponent } from './pages/resources/massage-and-cancer/massage-and-cancer.component';
import { WhatToDoEmergencyComponent } from './pages/resources/what-to-do-emergency/what-to-do-emergency.component';
import { SavedPathologyComponent } from './pages/saved-pathology/saved-pathology.component';
import { VideosAndResourcesComponent } from './pages/videos-and-resources/videos-and-resources.component';
import { DiagnoseComponent } from './pages/videos/diagnose/diagnose.component';
import { IntakeComponent } from './pages/videos/intake/intake.component';
import { WelcomeComponent } from './pages/videos/welcome/welcome.component';
import { PreviousRouteService } from './services/previous-route.service';
import { tokenGetter } from './services/tokenGetter';

@NgModule({
  declarations: [
    AnchorDirective,
    AppComponent,
    LoginComponent,
    PathologyComponent,
    PathologyDetailsComponent,
    HeaderComponent,
    HeaderNavComponent,
    LoginFormComponent,
    PathologyShortComponent,
    SearchBoxComponent,
    NavBottomComponent,
    SavedPathologyComponent,
    FavoriteButtonComponent,
    VideoComponent,
    HelpComponent,
    ModalComponent,
    AboutRuthComponent,
    VideosAndResourcesComponent,
    FaqsComponent,
    BuyTheBookComponent,
    AddToHomeScreenComponent,
    PathologySearchComponent,
    PathologyListComponent,
    MenuDropdownComponent,
    AboutPopaComponent,
    WhatToDoEmergencyComponent,
    EndangermentSitesComponent,
    MassageAndCancerComponent,
    HygieneProtocolsComponent,
    WelcomeComponent,
    DiagnoseComponent,
    IntakeComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    TypeaheadModule.forRoot(),
    CollapseModule.forRoot(),
    /*ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable',
      scope: '/'
    }),*/
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost', 'umbraco-api.azurewebsites.net']
      }
    })
  ],
  providers: [
    { provide: PreviousRouteService, useValue: undefined },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
