<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <hr>
        <!-- <h1 class="mt-1 mb-3 ">Frequently Asked Questions </h1> -->

          <accordion #accordion [isAnimated]="true" [closeOthers]="true">

            <accordion-group #GetStarted [isOpen]="true">
              <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                <fa-icon [icon]="GetStarted.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                <span class="">How do I get started using this app in my practice?</span>
              </button>

              <p>Start by watching this <a [routerLink]="['/resources/video-welcome']" href="javascript:void(0)" >demo video</a> below from Ruth Werner, author of A Massage Therapist’s Guide to Pathology.
                <!-- (click)="scroll('demo_video')" -->
                Follow along with Ruth as she walks you through this easy-to-use program of nearly 200 of the most
                common diseases and conditions you might see in your practice, and the most important things you
                need to know about them to ensure the safety and comfort of your client. No more guessing in the
                treatment room. This resource gives you the confidence you need to make best-practice
                decisions for every client.
              </p>
              <div id="demo_video" class="video_holder" >
                <a [routerLink]="['/resources/video-welcome']"
                alt="Play Video"
                class="d-sm-block btn-block holder vidLink" >
                  <img [src]="this.popaImgs + 'Ruth-welcome-vidBtn-654px.jpg' "  class="img-fluid vid_poster" />
                  <button type="button"
                  class="btn btn-secondary ng-tns-c85-2 float-md-right mt-2 mb-2 font-weight-bold text-light">Play Video</button>
                </a>

                <!-- <app-video class="card-img-top"
                  Poster="https://cdn.abmp.com/public/image/pocket-pathology/RuthWelcome_v2.jpg"
                  [source]="[{ src: this.vidSourceIntro,
                  type: 'application/x-mpegURL' }]"></app-video> -->

              </div>

            </accordion-group>

            <accordion-group #ConditionMissing>
              <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                <fa-icon [icon]="ConditionMissing.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                <span class="">I’m looking for a condition that isn’t on the Pocket Pathology list. Why isn’t it there?</span>
              </button>
              <p>ABMP Pocket Pathology focuses on the most common pathologies massage therapists and bodyworkers might find in their practice.
                From A to Z, this list was vetted to bring you information on conditions you will see again and again throughout
                the course of your career. Don’t see what you are looking for? Go to the Pocket Pathology home page,
                enter your search term, and click the Search All Content box to see if your term shows up elsewhere in the program. </p>
            </accordion-group>

            <accordion-group #MoreConditionInfo>
                <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                  <fa-icon [icon]="MoreConditionInfo.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                  <span class="">How can I get more information about the conditions listed in Pocket Pathology?</span>
                </button>
              <p>
                For information on more diseases and conditions, check out
                <a href="https://booksofdiscovery.com/product/a-massage-therapists-guide-to-pathology-7th-edition/"
                target="_blank"><em>A Massage Therapist’s Guide to Pathology</em> by Ruth Werner</a>.
                ABMP members  receive a
                <a target="_blank" href="https://www.abmp.com/members/business-management/discounts-and-resources/discounts#books-of-discovery">
                  discount on all Books of Discovery </a> titles, including <em>A Massage Therapist’s Guide to Pathology</em>.
              </p>
            </accordion-group>

            <accordion-group #AddToHomeScreen>
                <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                  <fa-icon [icon]="AddToHomeScreen.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                  <span class="">How can I add this to my home screen?</span>
                </button>
              <p>Find the menu icon (top right for desktop users, bottom navigation for mobile users).
                Click there and scroll down to “Add App to Home Screen.”
                This will give you the directions you need to add Pocket Pathology to your specific computer or device.
               </p>
            </accordion-group>

            <accordion-group #SysReqs>
                <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                  <fa-icon [icon]="SysReqs.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                  <span class="">What are the system requirements for ABMP Pocket Pathology?</span>
                </button>
              <p>ABMP Pocket Pathology is supported by mainstream web browsers including Chrome,
                Safari, Firefox, and Microsoft Edge. If you are having trouble viewing
                ABMP Pocket Pathology in one of these browsers, make sure you have a recent version of your browser installed.
               </p>
            </accordion-group>

            <accordion-group #CanIUseTablet>
              <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                <fa-icon [icon]="CanIUseTablet.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                <span class="">Can I use my tablet or smartphone to access ABMP Pocket Pathology,
                  and can I access the program on more than one device?</span>
              </button>
              <p>Yes! You can use ABMP Pocket Pathology on all of your mobile devices
                and on more than one device.
                Simply log in to ABMP Pocket Pathology with the device you are using.
               </p>
            </accordion-group>

            <accordion-group #SourcesUsed>
                <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                  <fa-icon [icon]="SourcesUsed.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                  <span class="">What sources were used for Pocket Pathology?</span>
                </button>
                <p>The content used in this program comes exclusively from A <em>Massage Therapist’s Guide to Pathology</em>, 7th ed.,
                  by Ruth Werner. ABMP is proud to have partnered with the profession’s premier pathology expert to bring members
                  this exclusive resource. Werner’s book is found in massage schools across
                  the country and around the world, and practitioners use her text as one of the mainstays
                  in their own reference libraries.
                 </p>
            </accordion-group>

            <accordion-group #HowToGiveFeedback>
                <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                  <fa-icon [icon]="HowToGiveFeedback.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                  <span class="">How can I give feedback, make suggestions, or comment on ABMP Pocket Pathology?</span>
                </button>
              <p>We appreciate your willingness to take the time to let us know what you think!
                Email <a href="mailto:Pocketcontent@abmp.com" >PocketContent@abmp.com</a>.
                </p>
            </accordion-group>

            <accordion-group #HowToGiveFeedback>
                <button class="btn btn-link pl-0 font-weight-bold clearfix text-left" accordion-heading type="button">
                  <fa-icon [icon]="HowToGiveFeedback.isOpen ? faChevronUp : faChevronDown"></fa-icon>
                  <span class="">Can I use the information in Pocket Pathology to diagnose, or treat a client who may have a condition that is outside my scope of practice as an MT?</span>
                </button>
              <p>No. The information provided within Pocket Pathology is not intended as medical advice.
                 Care has been taken to confirm the accuracy of the information presented herein and to describe
                 generally accepted practices. However, ABMP, the author, editors, and publishers involved in this project,
                 are not responsible for omissions or errors or for any consequences from application of the information
                 in this program, and make no warranty, expressed or implied, regarding the completeness or accuracy
                 of the contents of this publication. Application of the information in a
                particular situation remains the practitioner’s professional responsibility,
                as is their adherence to scope of practice.
                </p>
            </accordion-group>
          </accordion>

          <div id="debug" class="appVersion text-right  mt-5  versiontest">
              <p class="smallerIsh"><a class="text-primary versiontest" href="https://www.whatsmybrowser.org/" target="_blank">Browser detail</a></p>
          </div>

      </main>

    </div>
  </div>

</div>
