import { Component, OnInit } from '@angular/core';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Constantvars } from '../../constantvars';
import { PreviousRouteService } from '../../services/previous-route.service';

import {
  faChevronDown,
  faChevronUp,
  faPlay,
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-videos-and-resources',
  templateUrl: './videos-and-resources.component.html',
  styleUrls: ['./videos-and-resources.component.scss']
})
export class VideosAndResourcesComponent implements OnInit {

  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public faPlayCircle = faPlayCircle;
  public faPlay = faPlay;

  public AppConstants: any;
  public pgTitle: string;
  public popaImgs: string;
  public prevURL: string;
  isAccordOpen: boolean = true;

  constructor(
    private dd: DeviceDetectorService,
    private previousRouteService: PreviousRouteService
     ) {
    this.AppConstants = Constantvars;
    this.popaImgs = Constantvars.popaImgs;
    this.pgTitle = 'Videos, Forms, and Resources';
   }


  ngOnInit() {
    this.prevURL = this.previousRouteService.getPreviousUrl()

    const device = this.dd.getDeviceInfo();
    if (this.dd.isMobile()) {
      this.isAccordOpen = false;
    }
    if (this.dd.isDesktop()) {
      this.isAccordOpen = true;
    }
    if (this.dd.isTablet()) {
      this.isAccordOpen = true;
    }
  }

}
