<div class="container-lg mt-sm-0 pt-sm-0 my-lg-3 my-md-3 mt-sm-4 ">

  <div class="row justify-content-end">

    <div class="d-none d-md-block col-lg-4 col-md-4 col-sm-2 col-2 mt-md-4 px-3 py-4">
    </div>

    <div class="col-lg-8 col-md-8 col-sm-10 col-xs-10  mt-md-4 px-3 py-3">
      <main class="transparent-card px-3 py-3">

      <h1 class="mt-1 mb-3">Help  </h1>
      <p>Watch a quick demo of the program.</p>

      <div class="mt-1 mb-4">
        <app-video
        class="card-img-top"
        Poster="https://cdn.abmp.com/public/image/pocket-pathology/RuthWelcome_v2.jpg"
        [source]="[{ src: this.vidSourceIntro,
        type: 'application/x-mpegURL' }]"></app-video>
      </div>

        <p>Have an ABMP Pocket Pathology question?
          Send your comments to <span><a href="mailto:Pocketcontent@abmp.com">PocketContent@abmp.com </a> </span>
        </p>


        <div id="debug" class="appVersion text-right  mt-5  versiontest">
            <p><a class="text-primary versiontest" href="https://www.whatsmybrowser.org/" target="_blank">Browser detail</a></p>
        </div>


      </main>
    </div>

  </div>
</div>
