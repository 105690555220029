import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Constantvars } from '../../../constantvars';

@Component({
  selector: 'app-hygiene-protocols',
  templateUrl: './hygiene-protocols.component.html',
  styleUrls: ['./hygiene-protocols.component.scss']
})
export class HygieneProtocolsComponent implements OnInit {

  public AppConstants: any;
  public faChevronLeft = faChevronLeft;
  public pgTitle: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.pgTitle = 'Hygienic Guidelines for Your Practice';
  }

  ngOnInit() {
  }

}
