import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { ConnectionService } from './connection.service';
import { ISavedPathology, SavedPathoService } from './saved-patho.service';
import { UserService } from './user.service';

// tslint:disable-next-line: no-empty-interface
export interface FavoritePathology extends ISavedPathology {
}

@Injectable({
  providedIn: 'root'
})
export class FavoritePathologyService extends SavedPathoService<FavoritePathology> implements OnDestroy {

  protected bufferTime: number = 1000;

  private endpoint = 'https://umbraco-api.azurewebsites.net/api/pocketpatho/user/favorite';

  protected getHttp(): Observable<FavoritePathology[]> {
    return this.userService.UserState$.pipe(
      filter(user => user !== null && user.IsLoggedIn() && (!this.connection.State || user.fromCache === false)),
      switchMap(() => this.http.get<FavoritePathology[]>(this.endpoint, {
        headers: { "Authorization" : "Bearer " + this.token }
      }))
    );
  }

  protected updateHttp(state: any[]): Observable<FavoritePathology[]> {
    return this.userService.UserState$.pipe(
      take(1),
      switchMap(User => this.http.patch<FavoritePathology[]>(this.endpoint, state, {
        headers: { "Authorizatoin" : "Bearer " + this.token }
      })),
    );
  }

  protected clearHttp(): Observable<void> {
    return this.userService.UserState$.pipe(
      take(1),
      switchMap(User => this.http.delete<void>(this.endpoint, {
        headers: { "Authorization" : "Bearer " + this.token }
      })),
    );
  }

  constructor(
    private http: HttpClient,
    appRef: ApplicationRef,
    userService: UserService,
    connection: ConnectionService
  ) {
    super('FavoritePathology', appRef, userService, connection);

    // Get the data from SSO to push to the initial state
    this.subs.sink = this.userService.UserState$.subscribe(user => {
      if (user && 'pocket_patho' in user.FullSSOData) {
        if ('favorite' in user.FullSSOData.pocket_patho) {
          if (!user.fromCache || !this.initial) {
            this.server$.next(user.FullSSOData.pocket_patho.favorite);
          }
        }
      } else if (user === null) {
        this.server$.next([]);
      }
    });

    // Refresh the data from the server on first time
    this.getHttp().subscribe(state => this.server$.next(state));

    // Get things wired up
    this.appRef.isStable.pipe(filter(stable => stable || !this.connection.State), take(1)).subscribe(() => {
      this.subs.sink = this.wireEventStream().subscribe(state => {
        this.server$.next(state);
      });
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public Sorted$(): Observable<FavoritePathology[]> {
    return this.state$.pipe(
      map(state => state.sort((a, b) => a.title.localeCompare(b.title)))
    );
  }

}
