<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>

        <button [routerLink]="['/videos-and-resources']"
        [ngClass]="AppConstants.btnClassBackToReso "
        type="button">
          <fa-icon [icon]="faChevronLeft" class="mr-2"></fa-icon>Videos, Forms, and Resources
        </button>

        <p class="mt-3">Medical emergencies in massage settings are relatively rare, but they do happen, and we need to be prepared beforehand. Here is a short list of medical emergencies that may arise in a massage practice, with recommendations for how to respond. </p>


        <h3 class="mt-4">Emergency Basics</h3>

        <p>It is very helpful to have an emergency contact for each of your clients. Make sure you have a spot for this on your client health intake forms.</p>

        <p>If your clients have chronic conditions like asthma, diabetes, or epilepsy, ask them how they would like you to handle related emergencies beforehand, and document this in their charts.</p>

        <p>Check your first aid kit regularly to be sure it is fully stocked.</p>

        <p>If you do need to call 911, spend the time while you’re waiting to gather as much information as you can about your client’s situation: were there any changes in their medication, have they eaten recently, what else might have made today different from other days?</p>

        <p>Also, post your local non-emergency dispatch number (this is easily found online) by your office phone, or enter it into your cellphone for quick access.</p>

        <h3 class="mt-4">Specific Cautions</h3>


        <h5 class="boldSubTitle"><span><span>Hypotension and Faintness</span></span></h5>

        <p>If the client feels faint or dizzy during or after their session, invite them to sit up and rest and have a drink of water, tea, or juice until they feel better. If they are not recovered within a few minutes, ask them how they would like you to proceed: to call their emergency contact, or get them a ride to the hospital—via rideshare or ambulance. If they are confused, disoriented, or nonresponsive, do both: call 911 <em>and</em> their emergency contact.</p>


        <h5 class="boldSubTitle"><span><span>Hypoglycemia</span></span></h5>

        <p>People whose blood sugar is unstable may find that massage triggers a sudden drop, leaving them dizzy, faint, sweaty, and headachy. In severe situations they may become unresponsive and “checked out.” &nbsp;This is why it’s a good idea to establish the client’s preferred method to deal with hypoglycemic episodes beforehand. They may have sugar tablets with them. Otherwise, juice, sugared soda, or milk can be given—<em>with care to make sure they don’t choke.</em> When they are stabilized, they can decide what to do next—whether they need to go to the doctor, or go on with their day. </p>


        <h5 class="boldSubTitle"><span><span>Seizure</span></span></h5>

        <p>Seizures can take many forms. Some are so subtle they’re barely noticeable, while others involve whole-body contractions and a risk of injury. If a client has a seizure that involves some involuntary contractions but not violent thrashing, then the best recourse is to stay nearby and help them reorient when the seizure has passed. If this recovery period is relatively quick, ask them if they’d like to continue with the massage, or if they’d like to come back another day. </p>

        <p>On the other hand, a large-scale tonic-clonic seizure can involve rigidity alternating with uncontrolled contractions of muscles in the trunk, arms, and legs. The person will lose consciousness, and may lose control of their bladder. The highest priority for this situation is to keep the client safe. If you can do it safely, help them onto the floor as quickly as possible, then clear away any objects that they might contact. Stay with them, but don’t try to hold them down, or put anything in their mouth. Try to roll them onto their side, and put something soft under their head. Time the seizure, and when they regain consciousness, ask if they would like you to call their emergency contact or a rideshare to get them home safely.</p>

        <p>Regardless of the type of seizure, if the post-seizure recovery period lasts longer than 15 minutes, call 911. </p>

        <p>The CDC also recommends calling 911 if any of these things are true:</p>

        <ul>
          <li><span>The person has never had a seizure before.</span></li>
          <li><span>The person has difficulty breathing or waking after the seizure.</span></li>
          <li><span>The seizure lasts longer than 5 minutes.</span></li>
          <li><span>The person has another seizure soon after the first one.</span></li>
          <li><span>The person is hurt during the seizure.</span></li>
          <li><span>The seizure happens in water.</span></li>
          <li><span>The person has a health condition like diabetes, heart disease, or is pregnant.</span></li>
        </ul>


        <h5 class="boldSubTitle"><span><span>Slip and Falls&nbsp;&nbsp; </span></span></h5>

        <p>The best response to these cases depends on the nature of the accident. If the client is unconscious, call 911 immediately. But if the injury is less severe but still needs medical care, offer to call their emergency contact or rideshare to take them to the hospital. For smaller accidents, use your first aid kit appropriately, and carefully document everything that happens.</p>

        <ul>
          <li>If a client who uses blood thinners has a fall, it is especially important to watch for the possibility that they hit their head. Even mild bumps of this kind can be dangerous, and always warrant calling 911.</li>
          <li>Make sure to call your insurance provider after this type of incident in case any issues arise.</li>
        </ul>


        <h5 class="boldSubTitle"><span><span>Headache with SNOOP signs</span></span></h5>

        <p>Most headaches are not cause for alarm, but some of them signal a dangerous situation. The SNOOP signs of a medical emergency connected to headaches are these:</p>

        <ul>
          <li>S: Systemic symptoms, including fever, muscle pain, weight loss</li>
          <li>N: Neurological signs or symptoms, including changes in cognition, loss of sensation, or weakness</li>
          <li>O: Onset—a fast onset (a “thunderclap headache”) can be a sign of stroke; a slow onset that gets progressively worse without respite is also a dangerous sign</li>
          <li>O: Older age—dangerous headaches are most common in people over 50 years old</li>
          <li>P: Prior headache history—if a current headache is severe and unlike any previous experiences it should be reported to a physician</li>
        </ul>


        <h5 class="boldSubTitle"><strong><span>Trouble Breathing</span></strong></h5>

        <p>Shortness of breath may develop for many reasons, but always requires attention. An asthmatic episode may be controlled with an inhaler, but other factors--- like a sudden onset of an allergic reactions that blocks the throat—may require an EpiPen or medical attention. &nbsp;</p>


        <h5 class="boldSubTitle"><strong><span>Stroke with FAST signs</span></strong></h5>

        <p>The FAST mnemonic was developed to help medical personnel identify signs of stroke quickly and accurately. The prognosis for stroke recovery depends on how quickly treatment is given, so this is an important safety precaution. FAST stands for…</p>

        <ul>
          <li><strong><span>F</span></strong>: Facial drooping on one side</li>
          <li><strong><span>A</span></strong>: Arm weakness</li>
          <li><strong><span>S</span></strong>: Speech difficulties with understanding or speaking</li>
          <li><strong><span>T</span></strong>: Time to call emergency services</li>
        </ul>

        <p>Other signs of stroke include asymmetric pupils, thunderclap headache, and disorientation.</p>


        <h5 class="boldSubTitle"><strong><span>Signs of Heart Attack</span></strong></h5>

        <p>It is unlikely that a person will have a heart attack in a massage setting, but we should be prepared, because early intervention leads to better recovery for patients. Signs and symptoms of heart attack typically include crushing chest pain that may spread to include arms, neck, and back; a sense of impending doom; nausea and heartburn; cold sweat; fatigue; and lightheadedness. </p>

        <ul>
          <li>Women often have a different symptomatic profile; they are less likely to have chest pain, and more likely to have abdominal pain, nausea, and shortness of breath. </li>
          <li>A client with these symptoms should get to a hospital without delay.</li>
        </ul>


        <h5 class="boldSubTitle"><strong><span>Your Role as a Health-Care Provider</span></strong></h5>

        <p>Other possible emergency situations may arise, including:</p>

          <ul>
            <li>You believe your client is in imminent danger of harming themselves or others</li>

            <li>You believe your client is the target of domestic violence</li>

            <li>You believe your client is self-harming</li>

            <li>Your client is a juvenile and you believe they are not safe at home</li>
          </ul>

        <p>If you have reason to believe that a person is violent, has a weapon,
          and is a threat to themselves or others, then calling 911 is appropriate. However, if you believe the threat is less immediate but still important (e.g., your client shares suicidal thoughts and you believe she intends to harm herself soon), then it is appropriate to call your local non-emergency dispatch number. They will be able to provide you with referrals for appropriate professionals who can help.</p>

      </main>
    </div> <!-- end Col -->
  </div> <!-- End Row -->
</div>
