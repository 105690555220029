<div [ngClass]="AppConstants.pgContainer">
  <div [ngClass]="AppConstants.pgRowJustifyRight">
    <div [ngClass]="AppConstants.pgMainCol">
      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <div class="field-item even Main_Content">
          <hr class="title_sub divider">
          <ng-container class="ios" *ngIf="isIOS === true || MacTablet === true">
            <div class="ios Safari" *ngIf="devicebrowser === 'Safari'">
              <h5><strong>iOS Safari Users</strong></h5>
              <p>1. Tap the share action icon
                <img src="https://www.abmp.com/sites/abmp.com/files/sharris/images/share-ios-shortcut-btn36px.png" class="icon_reference align-bottom">
                at the bottom of the screen.
              </p>
              <p>
                2. Scroll down and tap on
                <br /><strong>Add to Home Screen</strong>.
              </p>
              <p>
                <img class="screen_reference img-fluid" src="{{popaImgs + 'ios-add-to-home-screen012020.jpg'}}">
              </p>
              <p>
                3. Tap <strong>Add</strong>.
              <p>
                <img class="screen_reference img-fluid" src="{{popaImgs + 'ios-add-to-home-screen-last-step-012020.jpg'}} ">
              </p>
              <hr class="divider">
              <p>
                <img class="icon_reference_small float-left mr-3" src="assets/icons/icon-144x144.png">
              </p>
              <p>
                4. The icon will now be on your Home Screen, and you can open Pocket Pathology by clicking on the icon.
                <em>You may be prompted to log in again, the first time you open it using the icon.</em>
              </p>
              <hr class="divider">
              <p><strong>Still Not Working?</strong></p>
              <p>
                1. Check the footer for a Safari Icon near the bottom right of your screen.
                This means you're viewing the page from inside another app, instead of the Safari browser.
              </p>
              <p>
                <img class="icon_reference_small img-fluid float-none clearfix" src="{{popaImgs + 'footer-embedded-gmail-app-ios2.jpg'}} ">
              </p>
              <p>
                2. Tap that icon to open Pocket Pathology in Safari, then follow the instructions above.
              </p>
              <p>
                3. Still can’t install the app? Click on the "Browser Detail" link below
                and email it to <a href="mailto:Pocketcontent@abmp.com?subject=Adding App to Home Screen {{deviceOS}} | {{devicebrowser}} | {{browserMainVersion}} ">PocketContent@abmp.com</a>.
              </p>
            </div>
            <div class="ios NOTsafari" *ngIf="devicebrowser !== 'Safari'">
              <h5><strong>iOS Users</strong></h5>
              <p><strong>
                  Please use Safari to enable Home Screen App Feature on iOS.
                </strong></p>
            </div>
          </ng-container>
          <ng-container class="MobileOrTablet" *ngIf="isMobi === true || isTablet === true">
            <ng-container class="androidUsers" *ngIf="isAndroid === true">
              <div class="Chrome Android" *ngIf="devicebrowser === 'Chrome'">
                <h5><strong>Android Chrome Users</strong></h5>
                <div class="Chrome OLD" *ngIf="browserMainVersion < 73">
                  <p><strong>
                      Please update Chrome to enable Home Screen App Feature.
                    </strong></p>
                </div>
                <div class="Chrome modern" *ngIf="browserMainVersion > 72">
                  <p>
                    1. If you see this bar at the bottom of the home page, tap on it.
                  </p>
                  <p>
                    <img alt="android chrome app install menu" class="img-fluid" alt="Add ABMP Pocket Pathology to Home Screen" src="{{popaImgs + 'add-to-home-Android-2.png'}}" />
                  </p>
                  <p>
                    If you don’t see that bar at the bottom of the home page, then tap the menu icon to open the menu
                    <img alt="" class="img-fluid" width="32" height="32" src="https://www.abmp.com/sites/abmp.com/files/sharris/images/android--menu-40px.png" /> and tap <strong>Add to Home Screen</strong>.
                  </p>
                  <p>
                    2. When prompted to install the app, tap <strong>Install</strong>.
                  </p>
                  <p>
                    <img alt="" class="img-fluid" alt="Install app" src="{{popaImgs + 'app-install-alert-popa-chrome-1.png'}}" />
                  </p>
                  <hr class="divider">
                  <p>
                    <img class="icon_reference_small float-left mr-3 mb-2" src="assets/icons/icon-144x144.png">
                  </p>
                  <p>
                    3. The icon will now be on your Home Screen, and you can open Pocket Pathology by clicking on the icon.
                    <em>
                      You may be prompted to log in again, the first time you open it using the icon.
                    </em>
                  </p>
                  <hr class="divider clearfix">
                  <p><strong>Still Not Working?</strong></p>
                  <p>
                    1. Check to see if there's a left arrow at the top left of your screen.
                    This means you're viewing the page from inside another app, instead of the Chrome browser.
                  </p>
                  <p>
                    <img class="icon_reference_small img-fluid float-none clearfix" src="{{popaImgs + 'andro-emedded-header1.jpg'}} ">
                  </p>
                  <p>
                    2. Tap the 3 dots on the right to open the menu.
                  </p>
                  <p>
                    <img class="icon_reference_small img-fluid float-none clearfix" src="{{popaImgs + 'andro-emedded-header2-menu.jpg'}} ">
                  </p>
                  <p>
                    3. Select <strong>Open in Chrome</strong>,
                    then follow the instructions above to add Pocket Pathology to your home screen.
                  </p>
                  <p>
                    4. Still can’t install the app? Click on the "Browser Detail" link below
                    and email it to <a href="mailto:Pocketcontent@abmp.com?subject=Adding App to Home Screen {{deviceOS}} | {{devicebrowser}} | {{browserMainVersion}}">PocketContent@abmp.com</a>.
                  </p>
                </div>
              </div>
              <div class="Firefox Android" *ngIf="devicebrowser === 'Firefox'">
                <h5><strong>Android FireFox Users</strong></h5>
                <div class="Firefox OLD" *ngIf="browserMainVersion < 58">
                  <p><strong>
                      Please update Firefox to enable Home Screen App Feature.
                    </strong></p>
                </div>
                <div class="Firefox modern" *ngIf="browserMainVersion > 57">
                  <p>
                    To the right of the URL will be a home icon with a plus in it.
                  </p>
                  <p>
                    <img class="img-fluid" alt="firefox menu add to home screen icon step 1" src="{{popaImgs + 'screenshot-firefox-android-addressbar.jpg'}}" />
                  </p>
                  <p>
                    By selecting this icon,
                    you can add the website to your home screen just like any other application.
                  </p>
                  <p>
                    <img class="img-fluid" alt="firefox menu add to home screen step 2" src="{{popaImgs + 'screenshot-firefox-app-install-menu-screen2.jpg'}}" />
                  </p>
                  <p>
                    After selecting the add-to-home button, you'll be asked if you want to add it automatically or manually. For the former,
                    just select "Add Automatically," and for the latter, long-press on it, then drop it where you want on your home screen.
                  <p>
                    <img class="icon_reference_small float-left mr-3 img-fluid" src="assets/icons/icon-144x144.png">
                    Once added, the icon will provide a link to the website without ever opening the browser.
                    This website will operate just like any other game that you were to install onto your device via the Google Play Store.
                  </p>
                </div>
              </div>
              <div class="NotChrome NotFirefox " *ngIf="devicebrowser !== 'Chrome' && devicebrowser !== 'Firefox'">
                <h5><strong>We Recommend Using Chrome </strong></h5>
                <p><strong>
                    Please Install Chrome or Firefox to enable Home Screen App Feature.
                  </strong></p>
              </div>
            </ng-container>
          </ng-container>
          <ng-container class="NotMobi NotTablet" *ngIf="isMobi === false && isTablet === false">
            <div class="chromeUsers deskTop" *ngIf="devicebrowser == 'Chrome'">
              <h5><strong>Chrome Desktop Users </strong></h5>
              <p>1. Click the Plus Indicator at the top right of your browser window.
                <img alt="" class="clearfix img-fluid " src="{{popaImgs + 'screenshot-chrome-desktop-pwa-plus-indicator3.jpg'}}" />
              </p>
              <p>
                2. Then click <strong>Install</strong>.
              </p>
              <p>
                <img alt="" src="{{popaImgs + 'app-install-alert-popa-chrome-1.png'}}" />
              </p>
              <hr>
              <p>3. If you don't see that option, click the menu icon at the top right of your browser window to open the Chrome menu.
                <img class="img-fluid" alt="chrome menu add to home screen icon" src="https://www.abmp.com/sites/abmp.com/files/sharris/images/chrome-menu-icon.png" />
              </p>
              <p>
                4. Select <strong>Install ABMP Pocket Pathology</strong>.
              </p>
              <p><img class="img-fluid" alt="chrome menu add to home screen icon step to" src="{{popaImgs + 'add-to-home-chrome-menu-2.png'}}" />
              </p>
              <p>
                5. Then click <strong>Install</strong>.
              </p>
              <p>
                <img alt="" src="{{popaImgs + 'app-install-alert-popa-chrome-1.png'}}" />
              </p>
              <hr class="divider">
              <p class="lastparagraph">
                <img class="icon_reference_small float-left mr-3 mb-2" src="assets/icons/icon-144x144.png">
                6. The icon will save to your Desktop, and you can open Pocket Pathology by clicking on the icon.
                <em>You may be prompted to log in again, the first time you open it using the icon.</em>
              </p>
              <hr class="divider clearfix mt-2">
              <p>
                7. Still can’t install the app? Click on the "Browser Detail" link below
                and email it to <a href="mailto:Pocketcontent@abmp.com?subject=Adding App to Home Screen {{deviceOS}} | {{devicebrowser}} | {{browserMainVersion}} ">PocketContent@abmp.com</a>.
              </p>
              <p class="divider clearfix">&nbsp;</p>
            </div>
            <div class="NotChrome " *ngIf="devicebrowser !== 'Chrome'">
              <h5><strong>We Recommend Using Chrome </strong></h5>
              <p><strong>
                  Please Install Chrome or Firefox to enable Home Screen App Feature.
                </strong></p>
            </div>
          </ng-container>
          <p class="text-right smallerIsh">
            <a href="https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/" target="_blank">
              <em>More info on how to install web apps</em>
            </a>
          </p>
          <div id="debug" class="text-right smallerIsh">
            <p><a class="text-primary" href="https://www.whatsmybrowser.org/" target="_blank">Browser detail</a></p>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
