import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  public Poster: string = null;

  @Input()
  public AutoPlay: boolean = false;
  @Input()
  public AutoPlaySet: string = null;

  @Input()
  public source: videojs.Tech.SourceObject[];

  @ViewChild('video')
  public video: ElementRef<HTMLVideoElement>;

  public VideoJS: videojs.Player;

  constructor() { }

  public ngOnInit(): void {
    console.log('AutoPlay', this.AutoPlay);
  }

  public ngAfterViewInit(): void {
    this.VideoJS = videojs(this.video.nativeElement);
  }

  public ngOnDestroy(): void {
    if (this.VideoJS) {
      this.VideoJS.dispose();
      this.VideoJS = null;
    }
  }

}
