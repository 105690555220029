import { ISavedPathology } from '../services/saved-patho.service';
import { JwtToken } from './jwt.model';
export interface IPACSSO {
  Columns: { [key: string]: any };
}

export interface IPocketPathoSSO {
  favorite?: ISavedPathology[];
  recent?: ISavedPathology[];
}

export interface IDrupalSSO {
  current_user: { uid: string };
  csrf_token: string;
  logout_token: string;
  pac_sso: IPACSSO;
  pocket_patho: IPocketPathoSSO;
}

export interface IUser {
  ID: number;
  Name: { first: string, last: string };
  FullSSOData: IDrupalSSO;
  fromCache: boolean;
  cacheAge?: Date;
}

export class User implements IUser {

  public ID: number = null;
  public Name: { first: string, last: string } = { first: null, last: null};
  public FullSSOData: IDrupalSSO = null;

  public token: string = null;

  public fromCache: boolean = false;

  public cacheAge?: Date = null;

  public IsLoggedIn(): boolean {
    return this.ID !== null && this.ID !== 0;
  }

  constructor(jwtData: any) {
    if (jwtData === null) {
      return;
    }
    try {
      if ('pac' in jwtData) {
        this.ID = jwtData.pac.cst_recno;
        this.Name = { first: jwtData.given_name, last: jwtData.family_name };
        this.cacheAge = new Date(jwtData.nbf * 1000);
      } else if ('ObjectName' in jwtData) {
        this.ID = jwtData.Columns.cst_recno;
        this.Name = { first: jwtData.Columns.ind_first_name, last: jwtData.Columns.ind_last_name};
        this.cacheAge = new Date(jwtData.Columns.update * 1000);
        
      }
    } catch (e) {
      this.ID = null;
    }
    
  }

}
