import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Constantvars } from '../../../constantvars';

@Component({
  selector: 'app-endangerment-sites',
  templateUrl: './endangerment-sites.component.html',
  styleUrls: ['./endangerment-sites.component.scss']
})
export class EndangermentSitesComponent implements OnInit {

  public AppConstants: any;
  public faChevronLeft = faChevronLeft;
  public pgTitle: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.pgTitle = 'Endangerment Sites You Need to Know';

  }

  ngOnInit() {
  }

}
