import { PathologyStub } from './pathology-stub.interface';

export class Pathology {
  // tslint:disable: variable-name
  public nid: number = undefined;
  public title: string = undefined;
  public alias: string = undefined;
  public body: string = undefined;
  public adaptations: string = undefined;
  public common_names_search_terms: string[] = undefined;
  public medications: string = undefined;
  public notes: string = undefined;
  public outcomes: string = undefined;
  public overview: string = undefined;
  public related_pathologies: PathologyStub[] = undefined;
  public related_pathology_titles: string[] = undefined;
  public research_and_resources: string = undefined;
  public keywords: string[] = undefined;

  public get common_names_search_terms_flat(): string {
    if (this.common_names_search_terms && this.common_names_search_terms instanceof Array) {
      const flat = this.common_names_search_terms.join(', ');
      return flat.charAt(0).toUpperCase() + flat.slice(1);
    }
  }

  constructor(source?: any) {
    if (source) {
      for (const key in this) {
        if (key in source) {
            this[key] = source[key];
        }
      }
    }

    if (typeof this.related_pathology_titles === 'string') {
      this.related_pathology_titles = (this.related_pathology_titles as string).split(',').map(value => value.trim().toLowerCase());
    }

    if (typeof this.common_names_search_terms === 'string') {
      this.common_names_search_terms = (this.common_names_search_terms as string).split(',').map(value => value.trim().toLowerCase());
    }
  }

  public GetPathoStub(): PathologyStub {
    return {
      nid: this.nid,
      title: this.title,
      alias: this.alias
    };
  }

}
