<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3 overflow-auto">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <hr>

          <p>Books of Discovery publishes manual therapy textbooks and educational resources,
            including <em>A Massage Therapist’s Guide to Pathology </em>by Ruth Werner. </p>

            <a href="https://booksofdiscovery.com/product/a-massage-therapists-guide-to-pathology-7th-edition/" target="_blank">
              <img src="https://cdn.abmp.com/public/image/pocket-pathology/MTsGuideToPathologyCoverWeb190px.jpg"
              class="border border-primary portrait img-fluid float-left mr-3 mb-1 mt-2" /></a>


          <p>Werner’s textbook is found in massage schools across the country and is a
            premier pathology resource for professionals throughout their careers. </p>

          <p>Books of Discovery offers a 25 percent discount for all ABMP members,
            which can be used on all products except apps and combination specials.
            Buy online at
            <a href="http://www.booksofdiscovery.com/" target="_blank">www.booksofdiscovery.com</a>
            and enter the coupon code <strong>ABMP77</strong> to receive your discount.</p>

            <!-- <div class="card-body">

        </div> -->
      </main>
    </div> <!-- end Col -->

  </div> <!-- End Row -->

</div>
