import { Component, OnInit } from '@angular/core';
import { Constantvars } from '../../constantvars';

@Component({
  selector: 'app-about-ruth',
  templateUrl: './about-ruth.component.html',
  styleUrls: ['./about-ruth.component.scss']
})
export class AboutRuthComponent implements OnInit {

  public AppConstants: any;
  public pgTitle: string;
  public popaImgs: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.popaImgs = Constantvars.popaImgs;
    this.pgTitle = 'About Ruth Werner';
   }

  public ngOnInit(): void {
  }

}
