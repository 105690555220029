import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutPopaComponent } from './pages/about-popa/about-popa.component';
import { AboutRuthComponent } from './pages/about-ruth/about-ruth.component';
import { AddToHomeScreenComponent } from './pages/add-to-home-screen/add-to-home-screen.component';
import { BuyTheBookComponent } from './pages/buy-the-book/buy-the-book.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { HelpComponent } from './pages/help/help.component';
import { LoginComponent } from './pages/login/login.component';
import { PathologyDetailsComponent } from './pages/pathology/pathology-details/pathology-details.component';
import { PathologyListComponent } from './pages/pathology/pathology-list/pathology-list.component';
import { PathologySearchComponent } from './pages/pathology/pathology-search/pathology-search.component';
import { PathologyComponent } from './pages/pathology/pathology.component';
import { EndangermentSitesComponent } from './pages/resources/endangerment-sites/endangerment-sites.component';
import { HygieneProtocolsComponent } from './pages/resources/hygiene-protocols/hygiene-protocols.component';
import { MassageAndCancerComponent } from './pages/resources/massage-and-cancer/massage-and-cancer.component';
import { WhatToDoEmergencyComponent } from './pages/resources/what-to-do-emergency/what-to-do-emergency.component';
import { SavedPathologyComponent } from './pages/saved-pathology/saved-pathology.component';
import { VideosAndResourcesComponent } from './pages/videos-and-resources/videos-and-resources.component';
import { DiagnoseComponent } from './pages/videos/diagnose/diagnose.component';
import { IntakeComponent } from './pages/videos/intake/intake.component';
import { WelcomeComponent } from './pages/videos/welcome/welcome.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: '', component: PathologyComponent, canActivate: [AuthGuardService], data: { animation: 'pathology' },
    children: [
      { path: '', component: PathologySearchComponent, canActivate: [AuthGuardService], data: { animation: 'pathology' } },
      { path: 'list', component: PathologyListComponent, canActivate: [AuthGuardService],
        data: { title: 'Pathology List', animation: 'pathology' } },
    ]
  },
  { path: 'about-ruth', component: AboutRuthComponent, canActivate: [AuthGuardService],
    data: { title: 'About Ruth Werner', animation: 'aboutRuth' } },
  { path: 'pathology/:alias', component: PathologyDetailsComponent, canActivate: [AuthGuardService],
    data: { title: null, animation: 'pathoAlias' } },
  { path: 'recent', component: SavedPathologyComponent, canActivate: [AuthGuardService],
    data: { title: 'Recent Pathologies', type: 'recent', animation: 'Recent' } },
  { path: 'favorite', component: SavedPathologyComponent, canActivate: [AuthGuardService],
    data: { title: 'Saved Pathologies', type: 'favorite', animation: 'Fave' } },
  { path: 'videos-and-resources', component: VideosAndResourcesComponent, canActivate: [AuthGuardService],
  data: { title: 'Videos, Forms, and Resources', animation: 'videoPage' } },

  { path: 'help', component: HelpComponent, data: { title: 'Help', animation: 'Help' } },
  { path: 'faqs', component: FaqsComponent, data: { title: 'Frequently Asked Questions', animation: 'faqs' } },
  { path: 'about-this-app', component: AboutPopaComponent, data: { title: 'About Pocket Pathology', animation: 'about-this-app' } },
  { path: 'add-to-home-screen', component: AddToHomeScreenComponent,
    data: { title: 'How to Install Pocket Pathology', animation: 'addToHome' } },
  { path: 'buy-the-book', component: BuyTheBookComponent, data: { title: 'Buy the Book', animation: 'buytheBook' } },

  { path: 'resources/emergency', component: WhatToDoEmergencyComponent,
    data: { title: 'What To Do in an Emergency', animation: 'buytheBook' } },
  { path: 'resources/video-welcome', component: WelcomeComponent,
    data: { title: 'Welcome Video', animation: 'welcome' } },
  { path: 'resources/video-intake', component: IntakeComponent,
    data: { title: 'Intake Video', animation: 'welcome' } },
  { path: 'resources/video-diagnose', component: DiagnoseComponent,
    data: { title: 'Do Not Diagnose Video', animation: 'welcome' } },
  { path: 'resources/massage-and-cancer', component: MassageAndCancerComponent,
    data: { title: 'Massage Therapy for People with Cancer', animation: 'buytheBook' } },
  { path: 'resources/endangerment-sites', component: EndangermentSitesComponent,
    data: { title: 'Endangerment Sites You Need to Know', animation: 'buytheBook' } },
  { path: 'resources/hygiene-protocols', component: HygieneProtocolsComponent,
    data: { title: 'Hygienic Guidelines for Your Practice', animation: 'buytheBook' } },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
