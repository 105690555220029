<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">

        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <hr>
        <button
        [routerLink]="['/videos-and-resources']"
        [ngClass]="AppConstants.btnClassBackToReso"
        type="button">
          <fa-icon [icon]="faChevronLeft" class="mr-2"></fa-icon>Videos, Forms, and Resources
        </button>

        <app-video
        class="card-img-top"
        [Poster]="this.vidJpg"
        [source]="[{ src: this.vidURL, type: this.vidType }]"
        [AutoPlay]="AppConstants.autoplay"
        ></app-video>

      </main>

    </div>
  </div>
</div>
