import { Component, OnInit } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Constantvars } from '../../../constantvars';

@Component({
  selector: 'app-what-to-do-emergency',
  templateUrl: './what-to-do-emergency.component.html',
  styleUrls: ['./what-to-do-emergency.component.scss']
})
export class WhatToDoEmergencyComponent implements OnInit {
  public AppConstants: any;
  public faChevronLeft = faChevronLeft;
  public pgTitle: string;

  constructor() {
    this.AppConstants = Constantvars;
    this.pgTitle = 'What To Do In An Emergency';
  }

  ngOnInit() {
  }

}
