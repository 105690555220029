<ng-container *ngIf="recent | async as Recent; else loading">

  <app-modal *ngIf="Recent.length === 0 && ! ModalHasBeenShown"></app-modal>

  <div class="container-lg" *ngIf="Loading$ | async; else loading">
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</ng-container>

<ng-template #loading>
  <div class="container-lg">
    <main>
      <div class="row justify-content-end">
        <div class="col-lg-6 col-md-6">
          <div class="jumbotron mt-4">
            <div class="d-flex align-items-center justify-content-center">
              <div class="spinner-border" role="status" aria-hidden="true"></div>
              &nbsp;
              <strong>Loading Pathologies...</strong>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</ng-template>
