import { Component, Input, OnInit } from '@angular/core';
import { faBookmark as faBookmarkSolid, faHistory, faHome, faUndo, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nav-bottom',
  templateUrl: './nav-bottom.component.html',
  styleUrls: ['./nav-bottom.component.scss']
})
export class NavBottomComponent implements OnInit {

  @Input()
  public hideMenu: boolean = true;

  public faHome = faHome;
  public faHistory = faHistory;
  public faUndo = faUndo;
  public faQuestion = faQuestionCircle;
  public faBookmarkSolid = faBookmarkSolid;

  constructor() {
  }

  public ngOnInit(): void {
  }

}
