<div class="container-lg">
  <main class="row justify-content-md-center">
    <div class="col col-md-10 col-lg-8">

      <app-login-form></app-login-form>

      <div class="jumbotron text-center">
        <p class="lead">Not an ABMP Member?</p>
        <p><a href="/signup" target="_blank">Join Now</a></p>
      </div>

    </div>
  </main>
</div>
