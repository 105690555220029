import { Component, Inject, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { Constantvars } from '../../constantvars';
import { PreviousRouteService } from '../../services/previous-route.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;

  public AppConstants: any;
  public pgTitle: string;
  public popaImgs: string;
  public prevURL: string;

  public vidSourceIntro = 'https://videos.abmp.com/public/pocketpatho-Long-Intro/Default/HLS/pocketpatho-Long-Intro.m3u8';

  constructor(
    private previousRouteService: PreviousRouteService,
  ) {
    this.AppConstants = Constantvars;
    this.popaImgs = Constantvars.popaImgs;
    this.pgTitle = 'Frequently Asked Questions';
   }

  public ngOnInit(): void {
    this.prevURL = this.previousRouteService.getPreviousUrl();
  }

  scroll(id) {
    const el = document.getElementById(id);
    el.scrollIntoView({behavior: 'smooth'});
  }

}
