<div [ngClass]="AppConstants.pgContainer" >
  <div [ngClass]="AppConstants.pgRowJustifyRight" >
    <div [ngClass]="AppConstants.pgMainCol" >

      <main class="transparent-card px-3 py-3">
        <h1 class="mb-3 realcase">{{pgTitle}} </h1>
        <hr>

        <!-- <h1 class="mt-1 mb-3">About Pocket Pathology</h1> -->

        <p>Whether you’re in a private practice, small clinic, day spa, or massage franchise, this web-based app from ABMP can give you the information you need to make the most appropriate treatment decisions for your clients who live with health challenges.&nbsp; </p>

        <p>Developed in partnership with
          <a [routerLink]="['/about-ruth']" >Ruth Werner</a>,
          author of <em>A Massage Therapist’s Guide to Pathology</em>, and Books of Discovery,
          ABMP Pocket Pathology is a quick reference program that puts key information for nearly
          200 common pathologies at your fingertips. Pocket Pathology lets you go into your sessions
          armed with the knowledge necessary to make the best treatment decisions, and it’s easy to use
          whether you’re accessing it from your phone or laptop.</p>

        <h3>It's Easy to Use</h3>

        <p>Did your client’s health intake interview just reveal she’s been diagnosed with
          diabetes and now you need a speedy review on the condition? Go to your Pocket Pathology
          app and type in <em>diabetes</em> to get the primary information you need to make best
          practice decisions about whether or not your touch will be beneficial to this client
          right now. </p>

        <p>Quick, bullet-pointed information lets you assess how to adapt your work to accommodate clients
          and their various conditions. No more guessing in the treatment room. This resource gives
          you the confidence you need to make best-practice decisions for every client.</p>

        <p>Have more time and want a deeper dive into information about the pathology
          you’re seeking? Check out the “Research and Resources” section to read articles,
          watch webinars, or investigate the latest research on the subject. </p>

        <h3>Why Not All Pathologies?</h3>

        <p>ABMP Pocket Pathology focuses on the most common pathologies massage therapists and bodyworkers
          might find in their practice. From A to Z, this list was vetted to bring you
          information on conditions you will see again and again throughout the course of
          your career. For more conditions, check out
          <a target="_blank" href="https://booksofdiscovery.com/product/a-massage-therapists-guide-to-pathology-7th-edition/">
          <em>A Massage Therapist’s Guide to Pathology</em> by Ruth Werner</a>.
          ABMP members also receive a
              <a target="_blank" href="https://www.abmp.com/members/business-management/discounts-and-resources/discounts#books-of-discovery">
                discount on all Books of Discovery </a> titles.
          </p>

        <h3>Caution First</h3>

        <p>The information provided within Pocket Pathology is not intended as medical advice.
          Care has been taken to confirm the accuracy of the information presented herein and
          to describe generally accepted practices. However, ABMP, the author, editors, and publishers
          involved in this project, are not responsible for omissions or errors or for any consequences
          from application of the information in this program, and make no warranty, expressed or implied,
          regarding the completeness or accuracy of the contents of this publication. Application of the
          information in a particular situation remains the practitioner’s professional responsibility,
          as is their adherence to scope of practice.</p>

        <h3>Contact ABMP</h3>

        <p>Want to share your thoughts and feedback about ABMP Pocket Pathology? We want to hear from you.
          Send your comments to <a href="mailto:Pocketcontent@abmp.com" >PocketContent@abmp.com</a>. </p>

      </main>
    </div>
  </div>
</div>
