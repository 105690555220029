import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { Pathology } from '../../../models/pathology.model';
import { FavoritePathologyService } from '../../../services/favorite-pathology.service';
import { PathologyService } from '../../../services/pathology.service';
import { RecentPathologyService } from '../../../services/recent-pathology.service';

@Component({
  templateUrl: './pathology-details.component.html',
  styleUrls: ['./pathology-details.component.scss']
})
export class PathologyDetailsComponent implements OnInit, OnDestroy {

  public isFavPatho$: Observable<boolean>;
  public Pathology$: Observable<Pathology>;
  public Favorite$: Subject<boolean>;

  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;

  @ViewChild(AccordionComponent)
  public accordion: AccordionComponent;

  private subs = new SubSink();

  constructor(
    public pathologyService: PathologyService,
    private route: ActivatedRoute,
    public recentService: RecentPathologyService,
    private favoriteService: FavoritePathologyService,
    private title: Title
  ) {
  }

  public ngOnInit(): void {
    // also Need to redo the HTM template too - Definitely

    // Local subscriptions that get unsubscribed every time the route changes
    const subs = new SubSink();

    this.subs.sink = this.route.paramMap.subscribe(param => {
      subs.unsubscribe();

      if (this.accordion) {
        this.accordion.closeOtherPanels(null);
      }

      // get current nodeAlias from current url
      const alias = param.get('alias');

      // use New Service Not OLD- then search & Filter
      this.Pathology$ = this.pathologyService.GetSingle(alias).pipe(
        // Some Logging
        tap(patho => {
          console.log(patho);
        }),

        // Handler for empty patho node
        tap(patho => {
          if (patho === null || patho === undefined) {
            // TODO: Do something if the patho node is empty
          }
        }),

        // Filter out empty patho nodes from rest of logic
        filter(patho => patho !== null && patho !== undefined),

        // Set Page Title
        tap(patho => {
          this.title.setTitle(patho.title);
        }),

        // Create favorites observable
        tap(patho => {
          this.Favorite$ = new Subject<boolean>();

          const favorite$ = this.favoriteService.Has$({
            nid: patho.nid,
            status: true
          });

          // Have the UI reflect change immediately
          this.isFavPatho$ = merge(favorite$, this.Favorite$);

          // Use debounce before subscribing to rate control the server updates
          subs.sink = this.Favorite$.pipe(debounceTime(500)).subscribe(status => {
            if (status) {
              this.favoriteService.Add({
                nid: patho.nid,
                status: true,
                alias: patho.alias,
                title: patho.title
              });
            } else {
              this.favoriteService.Remove({
                nid: patho.nid,
                status: false,
                alias: patho.alias,
                title: patho.title
              });
            }
          });
        }),

        // Log recent patho
        tap(patho => {
          this.recentService.Add({
            nid: patho.nid,
            status: true,
            alias: patho.alias,
            title: patho.title
          });
        }),

      ); // END .pipe()
    }); // END paramMap.subscribe()
  } // END ngOnInit()

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public ToggleFaveStar(status: boolean): void {
    if (this.Favorite$) {
      this.Favorite$.next(status);
    }
  }
}
