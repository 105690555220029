import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, retryWhen, switchMap, tap } from 'rxjs/operators';

import { genericRetryStrategy } from '../generic-retry-strategy';
import { JwtToken } from '../models/jwt.model';
import { IDrupalSSO, User } from '../models/user.model';
import { ConnectionService } from './connection.service';
import { tokenGetter } from './tokenGetter';
import { tokenSetter } from './tokenSetter';
//import { runInContext } from 'vm';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userState$: Subject<User> = new ReplaySubject(1);

  public readonly UserState$ = this.userState$.asObservable();

  constructor(
    private httpClient: HttpClient,
    private connectionService: ConnectionService,
    private token: JwtToken,
    private jwtHelper: JwtHelperService,
  ) {
    this.UserState$.subscribe(user => console.log('UserState', user));
    if (localStorage instanceof Storage) {
      const userDetailJson = localStorage.getItem('UserDetail');

      if (userDetailJson) {
        console.log('UserDetail from local storage');
        try {
          const userDetailObject = JSON.parse(userDetailJson);
          if (userDetailObject && 'ID' in userDetailObject) {
            const userDetail = new User(userDetailObject);
            userDetail.fromCache = true;
            
            const expires = new Date();
            expires.setDate(expires.getDate() - 7);

            if (userDetail.cacheAge && userDetail.cacheAge > expires) {
              this.userState$.next(userDetail);
            }
          }
        } catch (e) {
          console.error(e);
          localStorage.removeItem('UserDetail');
        }
      }
    }
    
    if(!token.Value) {
        let localToken = localStorage.getItem('JWT_TOKEN');
        if(localToken) {
            token.Update(localToken);
        }
    }

    //Developer DEBUG; erase below
    /********************************************************************
    if (!token.Value) {
      token = new JwtToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJlNjM5ODRjYS0wYzMwLTQyN2UtOThmMy00YzBiYTliYTJiYmEiLCJuYW1lIjoiand0OjE1MzkyMjIiLCJnaXZlbl9uYW1lIjoiQW50aG9ueSIsImZhbWlseV9uYW1lIjoiU2FsYWl6Iiwibmlja25hbWUiOiJBbnRob255IiwiZW1haWwiOiJ6emFudGhvbnlAYWJtcC5jb216eiIsInBhYyI6eyJjc3RfcmVjbm8iOjE1MzkyMjIsIjVtbSI6eyJleHBpcmVzIjoiMjAyNC0wNi0zMFQwMDowMDowMCJ9LCJleGFtY29hY2giOnsiYWN0aXZlIjp0cnVlLCJleHBpcmVzIjoiMjAyNS0wNy0wMVQwMDowMDowMCIsImNvaG9ydHMiOnt9fX0sImp0aSI6ImZkNjYwYjAwLTQ0OWYtNDIwNy04NDhkLWRlY2MzMjY1MzFlYSIsInJvbGVzIjpbXSwibmJmIjoxNzA3NDA2MTA4LCJleHAiOjE3MDg2MTU3MDgsImlhdCI6MTcwNzQwNjEwOH0.mghHWE1h693VDFikV9908Jm49mO0RlylHXc1qH18g9k");
    }
    //************************************************************** */

    if (token.Value) {
      const jwt = this.jwtHelper.decodeToken(token.Value);
      console.log('UserDetail from JWT');
      const userDetail = new User(jwt);
      userDetail.fromCache = false;
      userDetail.token = token.Value;
      this.userState$.next(userDetail);
    }

    /* TODO: Not sure why this is called, but it's wiping the user
    this.GetUserInfo().subscribe({
      next: state => this.userState$.next(state),
      error: error => this.userState$.next(null)
    });
    */
  }

  private GetUserInfo(): Observable<User> {
    const tokenRequest = 'https://test.abmp.com/api/individual';
    const offlineRetry = genericRetryStrategy({
      maxRetryAttempts: 5,
      scalingDuration: 5000
    });

    return this.httpClient
      .get(tokenRequest)
      .pipe(
        retryWhen(errors => {
          if (this.connectionService.State === false) {
            return offlineRetry(errors);
          }

          return errors.pipe(map(error => {
            if (error.error && 'message' in error.error) {
              switch (error.error.message) {
                case 'Failed to aquire read lock':
                  // Do nothing to trigger a retry
                  break;
                default:
                  throw error;
              }
            } else {
              throw error;
            }
          }));
        }),
        catchError((err, caught) => this.HandleSSOError(err, caught)),
        map((individualResponse: any | Error) => {
          if (individualResponse instanceof Error) {
            if (localStorage && localStorage instanceof Storage) {
              localStorage.removeItem('UserDetail');
            }

            if (individualResponse === null) {
              return null;
            }

            throw individualResponse;
          }

          if (this.connectionService.State === false && individualResponse instanceof User) {
            return individualResponse;
          }

          if ('JWT' in individualResponse) {
            const userDetail = new User(individualResponse.JWT);
            userDetail.cacheAge = new Date();

            if (localStorage && localStorage instanceof Storage) {
              localStorage.setItem('UserDetail', JSON.stringify(userDetail));
            }

            return userDetail;
          }

          if (localStorage && localStorage instanceof Storage) {
            localStorage.removeItem('UserDetail');
          }

          return null;
        })
      );
  }

  public Logout(): Observable<null> {
    console.log('LOGOUT');
    return this.UserState$.pipe(
      switchMap(user => {
        if (user === null || user instanceof Error) {
          return of(null);
        }
      }),
      tap(() => {
        if (localStorage && localStorage instanceof Storage) {
          localStorage.removeItem('UserDetail');
        }

        this.token.Update(null);

        this.userState$.next(null);
      }),
      map(() => null)
    );
  }

  public Login(username: string, password: string): Observable<User> {
    console.log('LOGIN');
    const data = { "UserName": username, "Password": password };

    return this.httpClient
      //.post('https://pocket-patho.abmp.com/sso', data)
      .post('https://members.abmp.com/nfPACTest/eWeb/JWT?Site=ABMP', data, {observe: 'response' })
      .pipe(
        catchError((err, caught) => this.HandleSSOError(err, caught)),
        map(loginResult => {
          console.log(loginResult);
          if (loginResult instanceof Error) {
            if (localStorage && localStorage instanceof Storage) {
              localStorage.removeItem('UserDetail');
            }

            throw loginResult;
          }
          if (loginResult instanceof HttpResponse) {
            var t = loginResult.headers.get('X-JWT') as string;
            this.token.Update(t);
            if ('current_user' in loginResult.body || 'ObjectName' in loginResult.body) {
              const userDetail = new User(loginResult.body);
              userDetail.token = t;
              if (localStorage && localStorage instanceof Storage) {
                localStorage.setItem('UserDetail', JSON.stringify(userDetail));
              }
              console.log(userDetail);
              //if (userDetail.FullSSOData.pac_sso) {
              //  this.token.Update(userDetail.FullSSOData.pac_sso.Columns.JWT);
              //}

              return userDetail;
            }
          }
          if (localStorage && localStorage instanceof Storage) {
            localStorage.removeItem('UserDetail');
          }

          return null;
        }),
        tap(state => this.userState$.next(state))
    );
  }

  private HandleSSOError(error: HttpErrorResponse, caught: Observable<any>): Observable<Error | User | null> {
    if (this.connectionService.State === false && error.status >= 500) {
      return this.UserState$;
    }

    if (error.status === 401) {
      if (error.error && 'message' in error.error) {
        if (error.error.message === 'Login Required') {
          return of(new Error('Login Required'));
        }

        if (error.error.message === 'LoginByEmail Failed') {
          return of(new Error('Sorry, unrecognized email address or password.'));
        }

        if (error.error.message === 'LoginByCookie failed') {
          window.location.href = '/members?refresh=1&destination=members/pocket-pathology';
        }

        return of(new Error(error.error.message));
      }
    }

    if (error.error && 'message' in error.error) {
      if (error.error.message === 'Failed to aquire read lock') {
        return of (new Error(error.error.message));
      }

      return of(new Error('Unknown error: ' + error.error.message));
    }

    return of(new Error(`Unknown error: ${error.status} ${error.statusText} ${error.error}`));
  }

}
